import {
  FETCH_STORAGE_REQUEST,
  FETCH_STORAGE_SUCCESS,
  FETCH_STORAGE_FAILURE,
  ADD_STORAGE_ITEM_REQUEST,
  ADD_STORAGE_ITEM_SUCCESS,
  ADD_STORAGE_ITEM_FAILURE,
  UPDATE_STORAGE_ITEM_REQUEST,
  UPDATE_STORAGE_ITEM_SUCCESS,
  UPDATE_STORAGE_ITEM_FAILURE,
  DELETE_STORAGE_ITEM_REQUEST,
  DELETE_STORAGE_ITEM_SUCCESS,
  DELETE_STORAGE_ITEM_FAILURE,
  FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAILURE,
  FETCH_STORAGE_PREDICTION_REQUEST,
  FETCH_STORAGE_PREDICTION_SUCCESS,
  FETCH_STORAGE_PREDICTION_FAILURE,
  FETCH_RESOURCE_PREDICTION_REQUEST,
  FETCH_RESOURCE_PREDICTION_SUCCESS,
  FETCH_RESOURCE_PREDICTION_FAILURE,
  UPLOAD_ITEM_IMAGE_REQUEST,
  UPLOAD_ITEM_IMAGE_SUCCESS,
  UPLOAD_ITEM_IMAGE_FAILURE
} from '../actionTypes';

const initialState = {
  storage: [],
  transactions: [],
  storagePrediction: [],
  resourcePrediction: [],
  loading: false,
  error: null
};

const storageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORAGE_REQUEST:
    case ADD_STORAGE_ITEM_REQUEST:
    case UPDATE_STORAGE_ITEM_REQUEST:
    case FETCH_STORAGE_PREDICTION_REQUEST:
    case FETCH_RESOURCE_PREDICTION_REQUEST:
    case DELETE_STORAGE_ITEM_REQUEST:
      case UPLOAD_ITEM_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_STORAGE_SUCCESS:
      return {
        ...state,
        storage: action.payload,
        loading: false,
        error: null,
      };
      case UPLOAD_ITEM_IMAGE_SUCCESS:
      return {
        ...state,
        storage: state.storage.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
        loading: false,
        error: null,
      };
    case ADD_STORAGE_ITEM_SUCCESS:
      return {
        ...state,
        storage: [...state.storage, action.payload],
        loading: false,
        error: null,
      };
    case UPDATE_STORAGE_ITEM_SUCCESS:
      return {
        ...state,
        storage: state.storage.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
        loading: false,
        error: null,
      };
    case DELETE_STORAGE_ITEM_SUCCESS:
      return {
        ...state,
        storage: state.storage.filter((item) => item._id !== action.payload),
        loading: false,
        error: null,
      };
    case FETCH_STORAGE_PREDICTION_SUCCESS:
      return {
        ...state,
        storagePrediction: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_RESOURCE_PREDICTION_SUCCESS:
      return {
        ...state,
        resourcePrediction: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_STORAGE_FAILURE:
    case ADD_STORAGE_ITEM_FAILURE:
    case UPDATE_STORAGE_ITEM_FAILURE:
    case DELETE_STORAGE_ITEM_FAILURE:
    case FETCH_STORAGE_PREDICTION_FAILURE:
    case FETCH_RESOURCE_PREDICTION_FAILURE:
    case  UPLOAD_ITEM_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_TRANSACTIONS_REQUEST:
    case ADD_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
        error: null,
      };
    case ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: [...state.transactions, action.payload],
        loading: false,
        error: null,
      };
    case FETCH_TRANSACTIONS_FAILURE:
    case ADD_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default storageReducer;
