import React  from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import gradoLogo from '../images/grado.png'; // Update the path to your grado.png file
import { logoutUser } from '../redux/actions/thunks/Auth.js'; // Import logoutUser thunk
import { useNavigate } from 'react-router-dom';

const NavigationBar = () => {
  const { pathname } = useLocation();
  const currentUser = useSelector(state => state.users.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  if (!token) {
    return null;
  }

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
  };

  return (
    <nav className="navbar dot-matrix">
      <Link to="/calendar">
        <img src={gradoLogo} alt="Grado Logo" className="navbar-logo" />
      </Link>
      <ul className="navbar-list">
        <li className="navbar-item">
          <Link to="/calendar" className={`navbar-link ${pathname === '/calendar' ? 'active' : ''}`} id="calendar-link">
            KALENTERI
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/storage" className={`navbar-link ${pathname === '/storage' ? 'active' : ''}`} id="storage-link">
            VARASTO
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/orders" className={`navbar-link ${pathname === '/orders' ? 'active' : ''}`} id="orders-link">
            TILAUKSET
          </Link>
        </li>
        {currentUser && (
          <React.Fragment>
            {(currentUser.role === 'admin' || currentUser.role === 'design') && (
              <li className="navbar-item">
                <Link to="/resources" className={`navbar-link ${pathname === '/resources' ? 'active' : ''}`} id="resources-link">
                  RESURSSIT
                </Link>
              </li>
            )}
            {currentUser.role === 'admin' && (
              <li className="navbar-item">
                <Link to="/users" className={`navbar-link ${pathname === '/users' ? 'active' : ''}`} id="users-link">
                  KÄYTTÄJÄT
                </Link>
              </li>
            )}
          </React.Fragment>
        )}
      </ul>
      <ul className="navbar-list">
        {currentUser && (
          <li className="navbar-item">
            <span className="navbar-username">Kirjautuneena: {currentUser.username}</span>
          </li>
        )}
        <li className="navbar-item">
          <button className="navbar-button" onClick={handleLogout}>
            KIRJAUDU ULOS
          </button>
        </li>
      </ul>
    </nav>
  );
  
};

export default NavigationBar;
