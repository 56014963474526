import {
    FETCH_SPENT_RESOURCES_REQUEST,
    FETCH_SPENT_RESOURCES_SUCCESS,
    FETCH_SPENT_RESOURCES_FAILURE,
    UPDATE_SPENT_RESOURCES_REQUEST,
    UPDATE_SPENT_RESOURCES_SUCCESS,
    UPDATE_SPENT_RESOURCES_FAILURE,
    FETCH_WEEKS_REQUEST,
    FETCH_WEEKS_SUCCESS,
    FETCH_WEEKS_FAILURE,
    UPDATE_WEEK_REQUEST,
    UPDATE_WEEK_SUCCESS,
    UPDATE_WEEK_FAILURE
} from '../actionTypes';



export const fetchSpentResourcesRequest = () => ({
    type: FETCH_SPENT_RESOURCES_REQUEST,
});

export const fetchSpentResourcesSuccess = (spentResources) => ({
    type: FETCH_SPENT_RESOURCES_SUCCESS,
    payload: spentResources,
});

export const fetchSpentResourcesFailure = (error) => ({
    type: FETCH_SPENT_RESOURCES_FAILURE,
    payload: error,
});

export const updateSpentResourcesRequest = () => ({
    type: UPDATE_SPENT_RESOURCES_REQUEST,
});

export const updateSpentResourcesSuccess = (updatedResources) => ({
    type: UPDATE_SPENT_RESOURCES_SUCCESS,
    payload: updatedResources,
});

export const updateSpentResourcesFailure = (error) => ({
    type: UPDATE_SPENT_RESOURCES_FAILURE,
    payload: error,
});

export const fetchWeeksRequest = () => ({
    type: FETCH_WEEKS_REQUEST,
});

export const fetchWeeksSuccess = (weeks) => ({
    type: FETCH_WEEKS_SUCCESS,
    payload: weeks,
});

export const fetchWeeksFailure = (error) => ({
    type: FETCH_WEEKS_FAILURE,
    payload: error,
});

export const updateWeekRequest = () => ({
    type: UPDATE_WEEK_REQUEST,
});

export const updateWeekSuccess = (week) => ({
    type: UPDATE_WEEK_SUCCESS,
    payload: week,
});

export const updateWeekFailure = (error) => ({
    type: UPDATE_WEEK_FAILURE,
    payload: error,
});