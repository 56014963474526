import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'; // Import thunk from redux-thunk

import ordersReducer from './reducers/ordersReducer';
import storageReducer from './reducers/storageReducer';
import notificationReducer from "./reducers/notificationReducer";
import spentResourcesReducer from './reducers/spentResourcesReducer';
import userReducer from './reducers/authReducer'
import quoteReducer from './reducers/quoteReducer';

// Combine reducers
const rootReducer = combineReducers({
  orders: ordersReducer,
  currentOrder: ordersReducer,
  storage: storageReducer,
  notification: notificationReducer,
  spentResources: spentResourcesReducer,
  users: userReducer,
  quote: quoteReducer
});

// Apply middleware (Redux Thunk)
const middleware = applyMiddleware(thunk);

// Create store with rootReducer and middleware
const store = createStore(rootReducer, middleware);

export default store;