import {
    fetchUsersRequest,
    fetchUsersSuccess,
    fetchUsersFailure,
    fetchUserRequest,
    fetchUserSuccess,
    fetchUserFailure,
    fetchUserCountRequest,
    fetchUserCountSuccess,
    fetchUserCountFailure,
    addUserRequest,
    addUserSuccess,
    addNewUserSuccess,
    addUserFailure,
    updateUserRequest,
    updateUserSuccess,
    updateUserFailure,
    deleteUserRequest,
    deleteUserSuccess,
    deleteUserFailure,
    loginRequest,
    loginSuccess,
    loginFailure,
    logout
  } from '../authAction';

  import { addNotification } from '../notificationAction';
  import axiosConfig, { setAuthorizationHeader } from '../../../utils/axiosConfig';
  const apiUrl = process.env.REACT_APP_API_URL;
  
  // Fetch Users
export const fetchUsers = () => async (dispatch) => {
    dispatch(fetchUsersRequest());
    dispatch(addNotification('users', 'loading', 'Haetaan käyttäjää..'));
    try {
        const response = await axiosConfig.get(`${apiUrl}/users`);
        dispatch(fetchUsersSuccess(response.data));
        dispatch(addNotification('users', 'success', 'Käyttäjä haettu onnistuneesti'));
    } catch (error) {
        dispatch(fetchUsersFailure(error.message));
        dispatch(addNotification('users', 'fail', 'Käyttäjien hakeminen epäonnistui'));
    }
};

  // Fetch UserCount
  export const fetchUserCount = () => async (dispatch) => {
    dispatch(fetchUserCountRequest());
    dispatch(addNotification('users', 'loading', 'Haetaan käyttäjätietokantaa'));
    try {
        const response = await axiosConfig.get(`${apiUrl}/usersCount`);
        dispatch(fetchUserCountSuccess(response.data));
        dispatch(addNotification('users', 'success', 'Käyttäjätietokannan haku onnistui'));
    } catch (error) {
        dispatch(fetchUserCountFailure(error.message));
        dispatch(addNotification('users', 'fail', 'Käyttäjätietokannan hakeminen epäonnistui'));
    }
};

  // Fetch User
  export const fetchUser = () => async (dispatch) => {
    dispatch(fetchUserRequest());
    dispatch(addNotification('users', 'loading', 'Haetaan käyttäjää...'));
    try {
        const response = await axiosConfig.get(`${apiUrl}/user`);
        dispatch(fetchUserSuccess(response.data));
        dispatch(addNotification('users', 'success', 'Käyttäjä haettu onnistuneesti'));
    } catch (error) {
        dispatch(fetchUserFailure(error.message));
        dispatch(addNotification('users', 'fail', 'Käyttäjän hakeminen epäonnistui'));
    }
};

// Add User
export const addUser = (userData) => async (dispatch, getState) => {
    const currentUser = getState().users.currentUser;
    dispatch(addUserRequest());
    dispatch(addNotification('users', 'loading', 'Lisätään käyttäjää...'));
    try {
        const response = await axiosConfig.post(`${apiUrl}/users`, userData);
        if (Object.keys(currentUser).length === 0) {
            dispatch(addUserSuccess(response));
            dispatch(loginUser(userData.username, userData.password));
        } else {
            dispatch(addNewUserSuccess(response));
        }
        dispatch(addNotification('users', 'success', 'Käyttäjä lisätty onnistuneesti'));
    } catch (error) {
        dispatch(addUserFailure(error.message));
        dispatch(addNotification('users', 'fail', 'Käyttäjän lisääminen epäonnistui'));
    }
};

// Update User
export const updateUser = (userId, newData) => async (dispatch) => {
    dispatch(updateUserRequest());
    dispatch(addNotification('users', 'loading', 'Päivitetään käyttäjää..'));
    try {
        const response = await axiosConfig.put(`${apiUrl}/users/${userId}`, newData);
        dispatch(updateUserSuccess(response.data));
        dispatch(addNotification('users', 'success', 'Käyttäjän päivitys onnistui'));
    } catch (error) {
        dispatch(updateUserFailure(error.message));
        dispatch(addNotification('users', 'fail', 'Käyttäjän päivitys epäonnistui'));
    }
};

// Delete User
export const deleteUser = (userId) => async (dispatch) => {
    dispatch(deleteUserRequest());
    dispatch(addNotification('users', 'loading', 'Poistetaan käyttäjää..'));
    try {
        await axiosConfig.delete(`${apiUrl}/users/${userId}`);
        dispatch(deleteUserSuccess(userId));
        dispatch(addNotification('users', 'success', 'Käyttäjä poistettu onnistuneesti'));
    } catch (error) {
        dispatch(deleteUserFailure(error.message));
        dispatch(addNotification('users', 'fail', 'Käyttäjän poistaminen epäonnistui'));
        console.log(error.message);
    }
};

// Login User
export const loginUser = (username, password) => async (dispatch) => {
    dispatch(loginRequest());
    dispatch(addNotification('auth', 'loading', 'Kirjaudutaan sisään...'));
    try {
        const response = await fetch(`${apiUrl}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'Tunnistautuminen epäonnistui');
        }

        const { user, token } = responseData;
        localStorage.setItem('token', token); // Store token in local storage
        setAuthorizationHeader(token); // Set the authorization header for future requests
        dispatch(loginSuccess(user));
        dispatch(addNotification('auth', 'success', 'Kirjautuminen onnistui'));
    } catch (error) {
        console.error('Login failed:', error.message);
        dispatch(loginFailure(error.message));
        dispatch(addNotification('auth', 'fail', `${error.message}`));
    }
};

  export const logoutUser = () => async (dispatch) => {
    localStorage.removeItem('token'); // Clear token from local storage
    setAuthorizationHeader(null); // Remove the authorization header
    dispatch(logout());
    dispatch(addNotification('auth', 'success', 'Logged out successfully'));
  };
