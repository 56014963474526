import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOpenOrders } from '../redux/actions/thunks/Orders';
import { Link } from 'react-router-dom';
import hazardImage from '../images/hazard.png';
import infoIcon from '../images/info.png';
import {
  startOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  isThisWeek,
  isWithinInterval,
  endOfWeek,
  format,
} from "date-fns";
import { fetchStoragePrediction, fetchResourcePrediction } from '../redux/actions/thunks/Storage';
import Tooltip from './ToolTip';
import { fetchWeeks } from '../redux/actions/thunks/SpentResources';
import { fetchUser } from '../redux/actions/thunks/Auth.js';

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));

  const orders = useSelector(state => state.orders.openOrders);
  const resourceWeeks = useSelector((state) => state.spentResources.weeks);
  const currentUser = useSelector((state) => state.users.currentUser);
  const storagePrediction = useSelector((state) => state.storage.storagePrediction);
  const resourcePrediction = useSelector((state) => state.storage.resourcePrediction);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(fetchStoragePrediction());
      dispatch(fetchResourcePrediction());
      dispatch(fetchUser());
      dispatch(fetchWeeks());
      dispatch(fetchOpenOrders());
  }, [dispatch]);


  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentWeek(currentWeek - 1);
      setCurrentMonth(subWeeks(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentWeek(currentWeek + 1);
      setCurrentMonth(addWeeks(currentMonth, 1));
    }
  };

  const getColorForOrder = (order) => {
    if (order.stepPackaging) {
      return '#90EE90';
    } else if (order.stepColoring1) {
      return '#ADD8E6';
    } else if (order.stepSanding) {
      return 'yellow';
    } else if (order.stepGluing) {
      return '#FFB6C1';
    } else if (order.stepCutting) {
      return 'violet';
    } else {
      return '#e0e0e0';
    }
  };

  const renderCells = () => {
    const weeks = [];
    const weekStartDates = [];
  
    // Calculate the start date of each week in the current month
    for (let i = 0; i < 7; i++) {
      const weekStartDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
      const weekStart = addWeeks(weekStartDate, i);
      weekStartDates.push(weekStart);
    }
  
    // Render each week horizontally
    for (let i = 0; i < 5; i++) {
      const weekStart = weekStartDates[i];
      const isCurrentWeek = isThisWeek(weekStart, { weekStartsOn: 1 }); // Check if the week is the current week
  
      const matchingWeek = storagePrediction.find(status =>
        isWithinInterval(new Date(status.weekStart), { start: weekStart, end: endOfWeek(weekStart, { weekStartsOn: 1 }) })
      );
      const materialsRunningOut = matchingWeek && matchingWeek.materials.roundedMaterialsRunningOut.length > 0;
      const materialsOutOfStorage = matchingWeek && matchingWeek.materials.roundedMaterialsRunningOut.some(material => material.amount < 0);
  
      let rowClassName = `row ${isCurrentWeek ? 'current-week' : ''}`;
  
      if (materialsRunningOut) {
        rowClassName += ' materials-running-out';
      }
  
      if (materialsOutOfStorage) {
        rowClassName = `row ${isCurrentWeek ? 'current-week' : ''} ${'materials-out-of-storage'}`;
      }
  
      // Filter orders for this week and exclude removed ones
      const ordersForWeek = orders.filter(order =>
        order.status !== "DELIVERED" && !order.isRemoved && isWithinInterval(new Date(order.delivery), { start: weekStart, end: endOfWeek(weekStart, { weekStartsOn: 1 }) })
      );
      // Fetch spent resources for this week
      const spentResourcesForWeek = resourcePrediction.filter(resource =>
        isWithinInterval(new Date(resource.weekStart), { start: weekStart, end: endOfWeek(weekStart, { weekStartsOn: 1 }) })
      );
  
      // Fetch the corresponding week data from weeks
      const correspondingWeek = resourceWeeks.find(week => week.weekNumber === format(weekStart, 'yyyy-ww'));
  
      // Calculate total available minutes
      const totalAvailableMinutes = correspondingWeek ? correspondingWeek.workers * 2400 : 0;
  
      // Render spent resources information for ToolTip content
      const spentResourcesContent = spentResourcesForWeek.map(resource => (
        <p key={resource.weekStart}>
          {resource.spentTime} / {totalAvailableMinutes} minuuttia
        </p>
      ));
  
      const orderElements = ordersForWeek.map(order => (
        <div key={order._id} className="order" style={{ backgroundColor: getColorForOrder(order) }}>
          <Link to={`/order/${order._id}`} className="order-link">
            {order.stepAmount === null && order.handRailAmount === null && (
              <>
                <Tooltip
                  content={
                    <div>
                      <p style={{ fontSize: '0.8vw', top: '1vw' }}>Materiaalitiedot puuttuu</p>
                    </div>
                  }
                >
                  <img src={hazardImage} alt="Hazard" className="hazard-icon" data-tip data-for={`hazard-tooltip-${order._id}`} />
                </Tooltip>
              </>
            )}
            <div className="number">{order.number}</div>
            <div className="customer-details">
              <div className="customer-name">{truncate(order.customer_name)}</div>
            </div>
          </Link>
        </div>
      ));
  
      const tooltipContent = (
        <div>
          {(spentResourcesContent.length > 0 && currentUser.role === 'admin') && (
            <>
              <p>Kuluva työaika tällä viikolla: </p>
              <ul>{spentResourcesContent}</ul>
            </>
          )}
          {materialsRunningOut && (
            <>
              <p>Tavaraa tämän viikon jälkeen:</p>
              <ul>
                {matchingWeek.materials.roundedMaterialsRunningOut.map((material, index) => (
                  <li key={`material-${index}`}>
                    {material.woodType === 'Käsijohteet' ? (
                      <>
                        {material.handRail_woodType} {material.handRailModel} käsijohde: {material.amount} kpl
                      </>
                    ) : (
                      <>
                        {material.woodType} {material.length} x {material.depth} x {material.thickness}mm: {material.amount} kpl
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      );
  
      const showTooltipIcon = (spentResourcesContent.length > 0 && currentUser.role === 'admin') || materialsRunningOut;
  
      weeks.push(
        <div className="col col-center" key={weekStart}>
          <div className="week-header-container">
            <div className={`week-header ${rowClassName}`}>
              <span style={{ fontSize: '1vw'}}>{getWeek(weekStart)}</span>
              {showTooltipIcon && (
                <Tooltip content={tooltipContent}>
                  <img src={infoIcon} alt="Info" className="info-icon" />
                </Tooltip>
              )}
            </div>
          </div>
          {orderElements}
        </div>
      );
    }
  
    return (
      <div className="body row">
        {weeks}
      </div>
    );
  };


  // Function to truncate the customer name if it doesn't fit
  const truncate = (text, maxLength = 45) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  const renderFooter = () => {
    return (
      <div className="footer row">
        <div className="col-start">
          <div className="gradoGreenButton" style={{ marginLeft: '20px' }} onClick={() => changeWeekHandle("prev")}>
            Edellinen viikko
          </div>
        </div>
        <div className="col col-center" style={{ fontSize: '13px', alignContent: 'center' }}>
          <span style={{ fontSize:'1vw', color: 'black' }}>
            <span style={{ width: '1vw', height: '1vw', display: 'inline-block', marginRight: '0.5vw', marginLeft: '2vw', backgroundColor: '#90EE90' }}></span>
            Pakattu
          </span>
          <span style={{ fontSize:'1vw', color: 'black' }}>
            <span style={{ width: '1vw', height: '1vw', display: 'inline-block', marginRight: '0.5vw', marginLeft: '2vw', backgroundColor: '#ADD8E6' }}></span>
            Värjäyksessä
          </span>
          <span style={{ fontSize:'1vw', color: 'black' }}>
            <span style={{ width: '1vw', height: '1vw', display: 'inline-block', marginRight: '0.5vw', marginLeft: '2vw', backgroundColor: 'yellow' }}></span>
            Hiottu
          </span>
          <span style={{ fontSize:'1vw', color: 'black' }}>
            <span style={{ width: '1vw', height: '1vw', display: 'inline-block', marginRight: '0.5vw', marginLeft: '2vw', backgroundColor: '#FFB6C1' }}></span>
            Liimattu
          </span>
          <span style={{ fontSize:'1vw', color: 'black' }}>
            <span style={{ width: '1vw', height: '1vw', display: 'inline-block', marginRight: '0.5vw', marginLeft: '2vw', backgroundColor: 'violet' }}></span>
            Leikattu
          </span>
          <span style={{ fontSize:'1vw', color: 'black' }}>
            <span style={{ width: '1vw', height: '1vw', display: 'inline-block', marginRight: '0.5vw', marginLeft: '2vw', backgroundColor: '#e0e0e0' }}></span>
            Ei työvaiheita
          </span>
        </div>
        <div className="col-end">
          <div className="gradoGreenButton" style={{ marginRight: '20px' }} onClick={() => changeWeekHandle("next")}>
            Seuraava viikko
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="calendar">
      {renderFooter()}
      {renderCells()}

    </div>
  );
};

export default Calendar;
