import React, { useState } from 'react';
import PersonInputModal from './PersonInputModal'; // Import the new modal component

const ModifyAmount = ({ initialAmount, onSubmit, onCancel, item }) => {

  const [amount, setAmount] = useState(initialAmount);
  const [count, setCount] = useState(0);
  const [showPersonModal, setShowPersonModal] = useState(false);

  const handleDecrease = () => {
    if (amount > 0) {
      setAmount(amount - 1);
      setCount(count - 1);
    }
  };

  const handleIncrease = () => {
    setAmount(amount + 1);
    setCount(count + 1);
  };

  const handleSubmit = () => {
    setShowPersonModal(true); // Show the person input modal
  };

  const handlePersonConfirm = (name) => {
    onSubmit(amount, item, name); // Pass the person's name to onSubmit
    setShowPersonModal(false);
  };

  const handlePersonCancel = () => {
    setShowPersonModal(false);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className="modify-amount-modal">
      <div className="modify-amount-window">
        <h2>Muuta määrää</h2>
        <div className="item-details" style={{ fontWeight: 'bold', color: 'black' }}>
          {item.isOther ? (
            <>
              <p>{item.item}</p>
              <p>{item.info}</p>
            </>
          ) : (
            item.isHandRail ? (
              <>
                <p>{item.woodType}</p>
                <p>{item.handRail_woodType} {item.handRailModel}</p>
                <p>{item.length}mm</p>
              </>
            ) : (
              <>
                <p>{item.woodType}</p>
                <p>{item.length}mm x {item.depth}mm</p>
                <p>{item.thickness}mm</p>
              </>
            )
          )}
        </div>
        <div className="counter" style={{ color: count < 0 ? 'red' : count > 0 ? 'green' : 'transparent', fontWeight: 'bold' }}>
          {count > 0 ? `+${count}` : count}
        </div>
        <div className="amount-controls">
          <button className="minus" onClick={handleDecrease}>-</button>
          <input className='input' type="text" value={amount} readOnly />
          <button className="plus" onClick={handleIncrease}>+</button>
        </div>
        <div className="buttons">
          <button className="cancel" style={{ color: 'red', width: '100px', fontWeight: 'bold' }} onClick={handleCancel}>Peru</button>
          <button className="submit" style={{ color: 'green', width: '100px', fontWeight: 'bold' }} onClick={handleSubmit}>Hyväksy</button>
        </div>
      </div>
      {showPersonModal && <PersonInputModal item={"Muuta määrää"} onConfirm={handlePersonConfirm} onCancel={handlePersonCancel} />}
    </div>
  );

};

export default ModifyAmount;