import {
  FETCH_SPENT_RESOURCES_REQUEST,
  FETCH_SPENT_RESOURCES_SUCCESS,
  FETCH_SPENT_RESOURCES_FAILURE,
  UPDATE_SPENT_RESOURCES_REQUEST,
  UPDATE_SPENT_RESOURCES_SUCCESS,
  UPDATE_SPENT_RESOURCES_FAILURE,
  FETCH_WEEKS_REQUEST,
  FETCH_WEEKS_SUCCESS,
  FETCH_WEEKS_FAILURE,
  UPDATE_WEEK_REQUEST,
  UPDATE_WEEK_SUCCESS,
  UPDATE_WEEK_FAILURE
} from '../actionTypes';

const initialState = {
  spentResources: [],
  weeks: [],
  loading: false,
  error: null,
};

// Reducer function
const spentResourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SPENT_RESOURCES_REQUEST:
    case UPDATE_SPENT_RESOURCES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SPENT_RESOURCES_SUCCESS:
      return {
        ...state,
        spentResources: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_SPENT_RESOURCES_SUCCESS:
      return {
        ...state,
        spentResources: state.spentResources.map((resource) =>
          resource._id === action.payload._id ? action.payload : resource
        ),
        loading: false,
        error: null,
      };
    case FETCH_SPENT_RESOURCES_FAILURE:
    case UPDATE_SPENT_RESOURCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_WEEKS_REQUEST:
    case UPDATE_WEEK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_WEEKS_SUCCESS:
      return {
        ...state,
        weeks: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_WEEKS_FAILURE:
    case UPDATE_WEEK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_WEEK_SUCCESS:
      return {
        ...state,
        weeks: state.weeks.map((week) =>
          week._id === action.payload._id ? action.payload : week
        ),
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default spentResourcesReducer;