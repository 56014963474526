import React, { useState } from 'react';

const SearchBar = ({ placeholder, handleChange }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleChange(value); // Pass the search term to the parent component
  };

  return (
    <div className="search-bar-container">
      <input
        className="search-input"
        type="search"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
      />
      <button className="search-button" type="submit">
        <i className="fas fa-search"></i>
      </button>
    </div>
  );
};

export default SearchBar;
