import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, addUser, updateUser, deleteUser, logoutUser } from '../redux/actions/thunks/Auth.js';
import DeleteConfirmation from './DeleteConfirmation';
import { FaCheck, FaTimes } from 'react-icons/fa';

const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users.users);
  const currentUser = useSelector(state => state.users.currentUser);
  const notifications = useSelector(state => state.notification);
  const [showMessage, setShowMessage] = useState(false);
  const [Message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    repeatPassword: '', // New field for repeating password
    role: 'production', // Default role
  });
  const [editMode, setEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    // Fetch users when component mounts
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    if (notifications.message === 'Käyttäjä lisätty onnistuneesti' ||
      notifications.message === 'Käyttäjän päivitys onnistui' ||
      notifications.message === 'Käyttäjä poistettu onnistuneesti' ||
      notifications.requestStatus === 'error'
    ) {
      setShowMessage(true);
      setMessage(notifications.message);
      // Set isSubmitted to false after 5 seconds
      setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }
  }, [notifications]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if passwords match
    if (formData.password !== formData.repeatPassword) {
      alert("Salasanat eivät täsmää");
      return;
    }

    if (editMode) {
      // Prevent non-admin users from updating the 'admin' user
      if (users.find(user => user._id === selectedUserId)?.username === 'admin' && currentUser.username !== 'admin') {
        alert("Et voi muokata pääkäyttäjää");
        return;
      }
      dispatch(updateUser(selectedUserId, formData));
    } else {
      // Check if username already exists
      const usernameExists = users.some(user => user.username === formData.username);
      if (usernameExists) {
        alert("Käyttäjänimi on jo käytössä");
        return;
      }
      if (!validatePassword(formData.password)) {
        alert("Salasanan tulee olla vähintään 8 merkkiä pitkä ja sisältää vähintään yksi iso kirjain, yksi pieni kirjain ja yksi numero");
        return;
      }
      // Add new user
      dispatch(addUser(formData))
        .then(() => { dispatch(fetchUsers()); });
    }
    // Clear form data
    setFormData({
      username: '',
      password: '',
      repeatPassword: '', // Reset repeat password field
      role: 'production',
    });
    // Exit edit mode
    setEditMode(false);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);

    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber;
  };

  const handleEdit = (user) => {
    setEditMode(true);
    setSelectedUserId(user._id);
    // Populate form data with selected user's information
    setFormData({
      username: user.username,
      password: '', // Optionally populate password if needed for editing
      repeatPassword: '', // Reset repeat password field
      role: user.role,
    });
  };

  const handleDelete = (userId, username) => {
    // Prevent non-admin users from deleting the 'admin' user
    if (username === 'admin' && currentUser.username !== 'admin') {
      alert("Et voi poistaa adminia");
      return;
    }

    // Show confirmation modal
    setUserToDelete({ userId, username });
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    // Delete user
    dispatch(deleteUser(userToDelete.userId)).then(() => {
      if (userToDelete.username === currentUser.username) {
        dispatch(logoutUser());
      }
    });
    setShowConfirmation(false);
    setUserToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setUserToDelete(null);
  };

  const handleAddNewUser = () => {
    setFormData({
      username: '',
      password: '',
      repeatPassword: '', // Reset repeat password field
      role: 'production',
    });
    setEditMode(false);
    setSelectedUserId(null);
  };

  const getRoleText = (role) => {
    switch (role) {
      case 'admin':
        return 'Pääkäyttäjä';
      case 'design':
        return 'Suunnittelu';
      case 'production':
        return 'Tuotanto';
      default:
        return role;
    }
  };

  return (
    <div className="users-container">
      <div className="users-header-flexbox">
        <div className="users-header" style={{display: 'flex'}}>
          <h1>Käyttäjät</h1>
          {showMessage && (
            <div className="notification">
              {notifications.requestStatus === 'success' && (
                <>
                  <FaCheck className="pulse-spin notification-success" style={{ marginLeft: '25px', fontSize: '1.5em' }} />
                  <span className="notification-text notification-success">{Message}</span>
                </>
              )}
              {notifications.requestStatus === 'error' && (
                <>
                  <FaTimes className="pulse-spin notification-error" style={{ marginLeft: '25px', fontSize: '1.5em' }} />
                  <span className="notification-text notification-error">{Message}</span>
                </>
              )}
            </div>
          )}
        </div>
        {editMode && (
          <button
            type="button"
            className="form-button form-button-new"
            onClick={handleAddNewUser}
          >
            Siirry uuden käyttäjän luontiin
          </button>
        )}
      </div>
      <form className="user-form" onSubmit={handleSubmit}>
        <input type="text" name="username" value={formData.username} onChange={handleInputChange} placeholder="Käyttäjätunnus" required />
        <input type="password" name="password" value={formData.password} onChange={handleInputChange} placeholder="Salasana" required />
        <input type="password" name="repeatPassword" value={formData.repeatPassword} onChange={handleInputChange} placeholder="Toista salasana" required />
        <select name="role" value={formData.role} onChange={handleInputChange} disabled={editMode && currentUser._id === selectedUserId}>
          <option value="production">Tuotanto</option>
          <option value="design">Suunnittelu</option>
          <option value="admin">Pääkäyttäjä</option>
        </select>
        <button type="submit" className="btn">{editMode ? 'Päivitä' : 'Lisää'}</button>
      </form>
      <ul className="user-list">
        {users.map((user, index) => (
          <li key={index} className="user-item">
            <span>
              {user.username} ({getRoleText(user.role)})
            </span>
            <div>
              <button
                className="form-button form-button-submit"
                onClick={() => handleEdit(user)}
                disabled={user.username === 'admin' && currentUser.username !== 'admin'}
              >
                Muokkaa
              </button>
              <button
                className="form-button form-button-delete"
                onClick={() => handleDelete(user._id, user.username)}
                disabled={user.username === 'admin'}
              >
                Poista
              </button>
            </div>
          </li>
        ))}
      </ul>
      {showConfirmation && (
        <DeleteConfirmation
          item={userToDelete.username}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};

export default Users;
