import {
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
  FETCH_ALL_ORDERS_REQUEST,
  FETCH_ALL_ORDERS_SUCCESS,
  FETCH_ALL_ORDERS_FAILURE,
  FETCH_OPEN_ORDERS_REQUEST,
  FETCH_OPEN_ORDERS_SUCCESS,
  FETCH_OPEN_ORDERS_FAILURE,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  CLEAR_ORDERS
} from '../actionTypes';

// Action creators for fetching orders request, success, and failure
export const fetchOrdersRequest = () => ({
  type: FETCH_ORDERS_REQUEST
});

export const fetchOrdersSuccess = (orders) => ({
  type: FETCH_ORDERS_SUCCESS,
  payload: orders
});

export const fetchOrdersFailure = (error) => ({
  type: FETCH_ORDERS_FAILURE,
  payload: error
});

//Action creator for fetching open orders
export const fetchOpenOrdersRequest = () => ({
  type: FETCH_OPEN_ORDERS_REQUEST
});

export const fetchOpenOrdersSuccess = (orders) => ({
  type: FETCH_OPEN_ORDERS_SUCCESS,
  payload: orders
});

export const fetchOpenOrdersFailure = (error) => ({
  type: FETCH_OPEN_ORDERS_FAILURE,
  payload: error
});

// Action creators for fetching a single order request, success, and failure
export const fetchOrderRequest = () => ({
  type: FETCH_ORDER_REQUEST
});

export const fetchOrderSuccess = (order) => ({
  type: FETCH_ORDER_SUCCESS,
  payload: order
});

export const fetchOrderFailure = (error) => ({
  type: FETCH_ORDER_FAILURE,
  payload: error
});

// Action creators for updating an order request, success, and failure
export const updateOrderRequest = () => ({
  type: UPDATE_ORDER_REQUEST
});

export const updateOrderSuccess = (updatedOrder) => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: updatedOrder
});

export const updateOrderFailure = (error) => ({
  type: UPDATE_ORDER_FAILURE,
  payload: error
});

export const clearOrders = () => ({
  type: CLEAR_ORDERS
});

export const fetchAllOrdersRequest = () => ({
  type: FETCH_ALL_ORDERS_REQUEST
});

export const fetchAllOrdersSuccess = () => ({
  type: FETCH_ALL_ORDERS_SUCCESS
});

export const fetchAllOrdersFailure = (error) => ({
  type: FETCH_ALL_ORDERS_FAILURE,
  payload: error
});