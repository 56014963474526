import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserCount, loginUser, addUser } from '../redux/actions/thunks/Auth.js';
import { useNavigate } from 'react-router-dom';
import { fetchRandomQuote } from '../redux/actions/thunks/Quotes.js';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState(''); // New state for repeated password
  const [showRegister, setShowRegister] = useState(false);
  const dispatch = useDispatch();
  const { loading, error, isUsers } = useSelector(state => state.users);
  const currentUser = useSelector(state => state.users.currentUser);
  const notifications = useSelector(state => state.notification);
  const quote = useSelector(state => state.quote.quote);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchUserCount());
    dispatch(fetchRandomQuote());
  }, [dispatch]);

  useEffect(() => {
    if (notifications.message === 'Käyttäjätietokannan haku onnistui' && isUsers === false) {
      setShowRegister(true);
    }
  }, [notifications, isUsers]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
        navigate('/calendar');
    }
  }, [currentUser, navigate]);

  const handleLogin = (e) => {
    
    e.preventDefault();
    if (!showRegister) {
    dispatch(loginUser(username, password));}
  };

  const handleRegister = () => {
    if (password !== repeatPassword) {
      alert("Salasanat eivät täsmää");
      return;
    }
    if (!validatePassword(password)) {
      alert("Salasanan tulee olla vähintään 8 merkkiä pitkä ja sisältää vähintään yksi iso kirjain, yksi pieni kirjain ja yksi numero");
      return;
    }
    dispatch(addUser({ username, password, role: 'admin' }));
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    
    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber;
  };

  return (
    <div className="login-container">
      
      <div className="login-form">
        <h1 className='theheader dot-matrix'>VERSTAS</h1>
        {quote && <div className="quote">{quote}</div>}
      {showRegister && (
        <h2>Rekisteröidy</h2>
      )}
      {!showRegister && (
        <h2>Kirjaudu sisään</h2>
      )}
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="username">Käyttäjätunnus</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Salasana</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {showRegister && (
          <div className="form-group">
            <label htmlFor="repeatPassword">Toista salasana</label>
            <input
              type="password"
              id="repeatPassword"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              required
            />
          </div>
          )}
          {!showRegister && (
            <button type="submit" className="login-button" disabled={loading}>
              {loading ? 'Kirjaudutaan...' : 'Kirjaudu'}
            </button>
          )}
          {showRegister && (
            <button onClick={handleRegister} className="register-button">
              Rekisteröidy
            </button>
          )}
        </form>
        {error && <div className="error-message">{notifications.message}</div>}
      </div>
    </div>
  );
};

export default LoginPage;
