import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../actionTypes';

const initialState = {
  stateType: null,
  requestStatus: null,
  message: 'No new notifications'
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      const { stateType, requestStatus, message } = action.payload;
      return {
        ...state,
        stateType,
        requestStatus,
        message
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        stateType: null,
        requestStatus: null,
        message: 'No new notifications',
      };
    default:
      return state;
  }
};

export default notificationReducer;
