import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../actionTypes';

// Action creator to add a notification
export const addNotification = (stateType, requestStatus, message) => {
  return {
    type: ADD_NOTIFICATION,
    payload: {stateType, requestStatus, message}
  };
};

// Action creator to remove a notification
export const removeNotification = () => {
  return {
    type: REMOVE_NOTIFICATION
  };
};
