import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    FETCH_USER_COUNT_REQUEST,
    FETCH_USER_COUNT_SUCCESS,
    FETCH_USER_COUNT_FAILURE,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    ADD_NEW_USER_SUCCESS,
    ADD_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT
} from '../actionTypes';

const initialState = {
    users: [],
    loading: false,
    error: null,
    currentUser: {},
    isUsers: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
        case FETCH_USER_REQUEST:
        case ADD_USER_REQUEST:
        case UPDATE_USER_REQUEST:
        case DELETE_USER_REQUEST:
        case FETCH_USER_COUNT_REQUEST:
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loading: false,
                error: null,
            };
        case ADD_USER_SUCCESS:
            return {
                ...state,
                users: [...state.users, action.payload],
                loading: false,
                error: null,
                currentUser: action.payload,
            };
        case ADD_NEW_USER_SUCCESS:
            return {
                ...state,
                users: [...state.users, action.payload],
                loading: false,
                error: null,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.map(user =>
                    user._id === action.payload._id ? action.payload : user
                ),
                loading: false,
                error: null,
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.filter(user => user._id !== action.payload),
                loading: false,
                error: null,
            };
        case FETCH_USER_SUCCESS:
        case LOGIN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                loading: false,
                error: null,
            };
        case FETCH_USER_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                isUsers: action.payload,
            };
        case FETCH_USERS_FAILURE:
        case FETCH_USER_FAILURE:
        case ADD_USER_FAILURE:
        case UPDATE_USER_FAILURE:
        case DELETE_USER_FAILURE:
        case FETCH_USER_COUNT_FAILURE:
        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case LOGOUT:
            return {
                ...state,
                currentUser: {},
            };
        default:
            return state;
    }
};

export default userReducer;