import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    FETCH_USER_COUNT_REQUEST,
    FETCH_USER_COUNT_SUCCESS,
    FETCH_USER_COUNT_FAILURE,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    ADD_NEW_USER_SUCCESS,
    ADD_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT
  } from '../actionTypes';
  
  // Fetch Users
  export const fetchUsersRequest = () => ({
    type: FETCH_USERS_REQUEST,
  });
  
  export const fetchUsersSuccess = (users) => ({
    type: FETCH_USERS_SUCCESS,
    payload: users,
  });
  
  export const fetchUsersFailure = (error) => ({
    type: FETCH_USERS_FAILURE,
    payload: error,
  });
  
// Fetch User
export const fetchUserRequest = () => ({
    type: FETCH_USER_REQUEST,
  });
  
  export const fetchUserSuccess = (user) => ({
    type: FETCH_USER_SUCCESS,
    payload: user,
  });
  
  export const fetchUserFailure = (error) => ({
    type: FETCH_USER_FAILURE,
    payload: error,
  });

  // Add User
  export const addUserRequest = () => ({
    type: ADD_USER_REQUEST,
  });
  
  export const addUserSuccess = (user) => ({
    type: ADD_USER_SUCCESS,
    payload: user,
  });

  export const addNewUserSuccess = (user) => ({
    type: ADD_NEW_USER_SUCCESS,
    payload: user,
  });
  
  export const addUserFailure = (error) => ({
    type: ADD_USER_FAILURE,
    payload: error,
  });
  
  // Update User
  export const updateUserRequest = () => ({
    type: UPDATE_USER_REQUEST,
  });
  
  export const updateUserSuccess = (user) => ({
    type: UPDATE_USER_SUCCESS,
    payload: user,
  });
  
  export const updateUserFailure = (error) => ({
    type: UPDATE_USER_FAILURE,
    payload: error,
  });
  
  // Delete User
  export const deleteUserRequest = () => ({
    type: DELETE_USER_REQUEST,
  });
  
  export const deleteUserSuccess = (userId) => ({
    type: DELETE_USER_SUCCESS,
    payload: userId,
  });
  
  export const deleteUserFailure = (error) => ({
    type: DELETE_USER_FAILURE,
    payload: error,
  });
  
  // Login
  export const loginRequest = () => ({
    type: LOGIN_REQUEST,
  });
  
  export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: user,
  });
  
  export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
  });

  export const logout = () => ({
    type: LOGOUT,
  });

  // Fetch User count
export const fetchUserCountRequest = () => ({
  type: FETCH_USER_COUNT_REQUEST,
});

export const fetchUserCountSuccess = (user) => ({
  type: FETCH_USER_COUNT_SUCCESS,
  payload: user,
});

export const fetchUserCountFailure = (error) => ({
  type: FETCH_USER_COUNT_FAILURE,
  payload: error,
});