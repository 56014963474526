
import {
  fetchStorageRequest,
  fetchStorageSuccess,
  fetchStorageFailure,
  addStorageItemRequest,
  addStorageItemSuccess,
  addStorageItemFailure,
  updateStorageItemRequest,
  updateStorageItemSuccess,
  updateStorageItemFailure,
  deleteStorageItemRequest,
  deleteStorageItemSuccess,
  deleteStorageItemFailure,
  fetchTransactionsRequest,
  fetchTransactionsSuccess,
  fetchTransactionsFailure,
  addTransactionRequest,
  addTransactionSuccess,
  addTransactionFailure,
  fetchStoragePredictionFailure,
  fetchStoragePredictionSuccess,
  fetchStoragePredictionRequest,
  fetchResourcePredictionFailure,
  fetchResourcePredictionSuccess,
  fetchResourcePredictionRequest,
  uploadItemImageRequest, 
  uploadItemImageSuccess, 
  uploadItemImageFailure
} from '../storageAction';

import { addNotification } from '../notificationAction';
import axiosConfig from '../../../utils/axiosConfig';
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchStorage = () => async (dispatch) => {
  dispatch(addNotification('storage', 'loading', 'Haetaan varastoa...'));
  dispatch(fetchStorageRequest());
  try {
    const response = await axiosConfig.get(`${apiUrl}/storage/`);

    const storage = response.data;

    dispatch(addNotification('storage', 'success', 'Varasto haettu'));
    dispatch(fetchStorageSuccess(storage));
  } catch (error) {
    dispatch(addNotification('storage', 'error', 'Varaston haku tietokannasta epäonnistui'));
    dispatch(fetchStorageFailure(error.message));
  }
};

export const addStorageItem = (itemData, person) => async (dispatch) => {
  dispatch(addNotification('storage', 'loading', 'Lisätään tuotetta...'));
  dispatch(addStorageItemRequest());
  try {
    const { image, ...dataWithoutImage } = itemData;

    const response = await axiosConfig.post(`${apiUrl}/storage/`, dataWithoutImage);

    const item = response.data;
    dispatch(addNotification('storage', 'success', 'Tuote lisätty onnistuneesti'));
    dispatch(addStorageItemSuccess(item));

    const transactionItem = {
      woodType: dataWithoutImage.woodType,
      length: dataWithoutImage.length,
      depth: dataWithoutImage.depth,
      thickness: dataWithoutImage.thickness,
    };

    if (dataWithoutImage.isOther) {
      transactionItem.item = dataWithoutImage.item;
      transactionItem.info = dataWithoutImage.info;
      transactionItem.isOther = dataWithoutImage.isOther
    } else if (dataWithoutImage.isHandRail) {
      transactionItem.handRail_woodType = dataWithoutImage.handRail_woodType;
      transactionItem.model = dataWithoutImage.handRailModel;
      transactionItem.isHandRail = dataWithoutImage.isHandRail
    }

    dispatch(addTransaction({
      person: person,
      item: transactionItem,
      amount: dataWithoutImage.amount,
      action: 'Uusi',
      time: new Date(),
    }));
  } catch (error) {
    dispatch(addNotification('storage', 'error', 'Tuotteen lisäys epäonnistui'));
    dispatch(addStorageItemFailure(error.message));
  }
};

export const updateStorageItem = (itemId, newData, oldData, action, person) => async (dispatch) => {
  dispatch(addNotification('storage', 'loading', 'Päivitetään tuotetta...'));
  dispatch(updateStorageItemRequest());
  try {
    const { image, ...newDataWithoutImage } = newData;

    const response = await axiosConfig.put(`${apiUrl}/storage/${itemId}`, newDataWithoutImage);

    const item = { ...response.data, image: oldData.image };
    dispatch(addNotification('storage', 'success', 'Tuote päivitetty onnistuneesti'));
    dispatch(updateStorageItemSuccess(item));

    if (action === 'ModifyAmount') {
      if ((newData.amount - oldData.amount) !== 0) {
        const transactionItem = {
          woodType: oldData.woodType,
          length: oldData.length,
          depth: oldData.depth,
          thickness: oldData.thickness,
        };

        if (oldData.isOther) {
          transactionItem.item = oldData.item;
          transactionItem.info = oldData.info;
          transactionItem.isOther = oldData.isOther
        } else if (oldData.isHandRail) {
          transactionItem.handRail_woodType = oldData.handRail_woodType;
          transactionItem.model = oldData.handRailModel;
          transactionItem.isHandRail = oldData.isHandRail
        }

        dispatch(addTransaction({
          person: person,
          item: transactionItem,
          amount: (newData.amount - oldData.amount),
          action: '',
          time: new Date(),
        }));
      }
    } else {
      const newDataWithoutLimit = { ...newData };
      const oldDataWithoutLimit = { ...oldData };

      delete newDataWithoutLimit.limit;
      delete oldDataWithoutLimit.limit;

      if (JSON.stringify(newDataWithoutLimit) !== JSON.stringify(oldDataWithoutLimit)) {
        const transactionItem = {
          woodType: newData.woodType,
          length: newData.length,
          depth: newData.depth,
          thickness: newData.thickness,
        };
        const oldTransactionItem = {
          woodType: oldData.woodType,
          length: oldData.length,
          depth: oldData.depth,
          thickness: oldData.thickness,
          amount: oldData.amount
        };

        if (newData.isOther) {
          transactionItem.item = newData.item;
          transactionItem.info = newData.info;
          transactionItem.isOther = newData.isOther;
        oldTransactionItem.item = oldData.item;
          oldTransactionItem.info = oldData.info;
          oldTransactionItem.isOther = oldData.isOther;
        } else if (newData.isHandRail) {
          transactionItem.handRail_woodType = newData.handRail_woodType;
          transactionItem.model = newData.handRailModel;
          transactionItem.isHandRail = newData.isHandRail;
          oldTransactionItem.handRail_woodType = oldData.handRail_woodType;
          oldTransactionItem.model = oldData.handRailModel;
          oldTransactionItem.isHandRail= oldData.isHandRail;
        }

        dispatch(addTransaction({
          person: person,
          item: transactionItem,
          oldItem: oldTransactionItem,
          amount: newData.amount,
          action,
          time: new Date(),
        }));
      }
    }
  } catch (error) {
    dispatch(addNotification('storage', 'error', 'Tuotteen päivitys epäonnistui'));
    dispatch(updateStorageItemFailure(error.message));
  }
};



export const deleteStorageItem = (itemId, itemData, person) => async (dispatch) => {
  dispatch(addNotification('storage', 'loading', 'Poistetaan tuotetta...'));
  dispatch(deleteStorageItemRequest());
  try {
    await axiosConfig.delete(`${apiUrl}/storage/${itemId}`);

    dispatch(addNotification('storage', 'success', 'Tuote poistettu onnistuneesti'));
    dispatch(deleteStorageItemSuccess(itemId));

    const transactionItem = {
      woodType: itemData.woodType,
      length: itemData.length,
      depth: itemData.depth,
      thickness: itemData.thickness,
    };

    if (itemData.isOther) {
      transactionItem.item = itemData.item;
      transactionItem.info = itemData.info;
      transactionItem.isOther = itemData.isOther;
      console.log("positetaan muut");
    } else if (itemData.isHandRail) {
      transactionItem.handRail_woodType = itemData.handRail_woodType;
      transactionItem.model = itemData.handRailModel;
      transactionItem.isHandRail = itemData.isHandRail;
    }

    dispatch(addTransaction({
      person: person,
      item: transactionItem,
      amount: itemData.amount,
      action: 'Poistettu',
      time: new Date(),
    }));
  } catch (error) {
    dispatch(addNotification('storage', 'error', 'Tuotteen poisto epäonnistui'));
    dispatch(deleteStorageItemFailure(error.message));
  }
};

// Fetch Transactions
export const fetchTransactions = (count) => async (dispatch) => {
  dispatch(fetchTransactionsRequest());
  try {
      const response = await axiosConfig.get(`${apiUrl}/transactions?count=${count}`);
      dispatch(fetchTransactionsSuccess(response.data));
  } catch (error) {
      dispatch(fetchTransactionsFailure(error.message));
      dispatch(addNotification('transactions', 'error', 'Tapahtumien haku epäonnistui'));
  }
};

// Add Transaction
export const addTransaction = (transactionData) => async (dispatch) => {
  //dispatch(addNotification('transactions', 'loading', 'Adding transaction...'));
  dispatch(addTransactionRequest());
  try {
      const response = await axiosConfig.post(`${apiUrl}/transactions`, transactionData); // Use axiosConfig for the request

      const transaction = response.data;
      dispatch(addTransactionSuccess(transaction));
      //dispatch(addNotification('transactions', 'success', 'Transaction added successfully'));
  } catch (error) {
      dispatch(addTransactionFailure(error.message));
      dispatch(addNotification('transactions', 'error', 'Tapahtuman lisääminen epäonnistui'));
  }
};

//Fetch storage prediction
export const fetchStoragePrediction = () => async (dispatch) => {
  dispatch(addNotification('storage', 'loading', 'Ladataan menekin ennustusta...'));
  dispatch(fetchStoragePredictionRequest());
  try {
    const response = await axiosConfig.get(`${apiUrl}/storagePrediction/`);
    const prediction = response.data; // Use response.data to get the response body
    dispatch(fetchStoragePredictionSuccess(prediction));
    dispatch(addNotification('storage', 'success', 'Menekin ennustus onnistui'));
  } catch (error) {
    console.error('Error fetching storage prediction:', error);
    dispatch(fetchStoragePredictionFailure(error.message));
    dispatch(addNotification('storage', 'error', 'Menekin ennustus epäonnistui'));
  }
};

//Fetch storage prediction
export const fetchResourcePrediction = () => async (dispatch) => {
  dispatch(addNotification('storage', 'loading', 'Ladataan kuluvia työaikoja...'));
  dispatch(fetchResourcePredictionRequest());
  try {
    const response = await axiosConfig.get(`${apiUrl}/resourcePrediction`);
    const prediction = response.data; // Use response.data to get the response body
    dispatch(fetchResourcePredictionSuccess(prediction));
    dispatch(addNotification('storage', 'success', 'Ennustetut työajat ladattu'));
  } catch (error) {
    console.error('Error fetching resource prediction:', error);
    dispatch(fetchResourcePredictionFailure(error.message));
    dispatch(addNotification('storage', 'error', 'Ennustettujen työaikojen haku epäonnistui'));
  }
};

export const uploadItemImage = (itemId, file) => {
  return async (dispatch) => {
    dispatch(uploadItemImageRequest());
    dispatch(addNotification('storage', 'loading', 'Lisätään kuvaa...')); // Dispatch loading notification

    try {
      const formData = new FormData();
      formData.append('image', file);

      const response = await axiosConfig.put(`${apiUrl}/storage/${itemId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const updatedItem = response.data;

      dispatch(uploadItemImageSuccess(updatedItem));
      dispatch(addNotification('storage', 'success', 'Kuva lisätty onnnistuneesti')); // Dispatch success notification
    } catch (error) {
      dispatch(uploadItemImageFailure(error.message));
      dispatch(addNotification('storage', 'error', 'Kuvan lisääminen epäonnistui')); // Dispatch error notification
    }
  };
};