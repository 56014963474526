import React, { useState } from 'react';
import PersonInputModal from './PersonInputModal';

const AddItem = ({ onSubmit, onClose }) => {
    const [formData, setFormData] = useState({
        woodType: '',
        length: '',
        depth: '',
        thickness: '',
        limit: '',
        amount: '',
        isHandRail: false,
        isOther: false
    });
    const [showPersonModal, setShowPersonModal] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowPersonModal(true); // Show the person input modal
    };

    const handlePersonConfirm = (name) => {
        onSubmit(formData, name); // Pass the person's name to onSubmit
        setShowPersonModal(false);
        onClose(); // Close the AddItem window after submission
    };

    const handlePersonCancel = () => {
        setShowPersonModal(false);
    };

    const handleCancel = () => {
        onClose(); // Close the AddItem window on cancel
    };

    return (
      <div className="add-item-modal" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="add-item-window" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', width: '440px' }}>
          <h2 style={{ textAlign: 'center' }}>Lisää tuote</h2>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="woodType-input">Puulaji:</label>
              <input
                type="text"
                name="woodType"
                value={formData.woodType}
                onChange={handleChange}
                required
                style={{ marginBottom: '10px' }}
              />
              <label htmlFor="length-input">Pituus:</label>
              <input
                type="text"
                name="length"
                value={formData.length}
                onChange={handleChange}
                required
                style={{ marginBottom: '10px' }}
              />
              <label htmlFor="depth-input">Leveys:</label>
              <input
                type="text"
                name="depth"
                value={formData.depth}
                onChange={handleChange}
                required
                style={{ marginBottom: '10px' }}
              />
            </div>
            <div style={{  marginBottom: '10px' }}>
              <label htmlFor="thickness-input">Paksuus:</label>
              <input
                type="text"
                name="thickness"
                value={formData.thickness}
                onChange={handleChange}
                required
                style={{ marginBottom: '10px' }}
              />
              <label htmlFor="limit-input">Varmuusraja:</label>
              <input
                type="text"
                name="limit"
                value={formData.limit}
                onChange={handleChange}
                required
                style={{ marginBottom: '10px' }}
              />
              <label htmlFor="amount-input">Määrä:</label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                required
                style={{ marginBottom: '10px' }}
              />
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <button type="submit" className="form-button form-button-new">Lisää tuote</button>
              <button type="button" onClick={handleCancel} className="form-button form-button-cancel">Peru</button>
            </div>
          </form>
        </div>
        {showPersonModal && <PersonInputModal onConfirm={handlePersonConfirm} onCancel={handlePersonCancel} />}
      </div>
    );
  
  
};

export default AddItem;