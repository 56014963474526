import {
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
  FETCH_OPEN_ORDERS_REQUEST,
  FETCH_OPEN_ORDERS_SUCCESS,
  FETCH_OPEN_ORDERS_FAILURE,
  FETCH_ALL_ORDERS_REQUEST,
  FETCH_ALL_ORDERS_SUCCESS,
  FETCH_ALL_ORDERS_FAILURE,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  CLEAR_ORDERS
} from '../actionTypes';

const initialState = {
  orders: [],
  loading: false,
  error: null,
  currentOrder: [],
  openOrders: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_REQUEST:
      case FETCH_OPEN_ORDERS_REQUEST:
    case FETCH_ALL_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
        error: null
      };
      case FETCH_OPEN_ORDERS_SUCCESS:
        return {
          ...state,
          openOrders: action.payload,
          loading: false,
          error: null
        };
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        currentOrder: action.payload,
        loading: false,
        error: null
      };
    case FETCH_ORDER_FAILURE:
      case FETCH_OPEN_ORDERS_FAILURE:
    case FETCH_ALL_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_ORDER_SUCCESS:
      const updatedOrders = state.orders.map(order =>
        order._id === action.payload._id ? action.payload : order
      );
      return {
        ...state,
        orders: updatedOrders,
        loading: false,
        error: null
      };
    case UPDATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CLEAR_ORDERS:
      return {
        ...state,
        orders: [],
        loading: false,
        error: null
      };
      case FETCH_ALL_ORDERS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null
        };
    default:
      return state;
  }
};

export default reducer;
