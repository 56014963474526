
import {
    fetchQuoteRequest,
    fetchQuoteSuccess,
    fetchQuoteFailure
} from '../quoteAction';
import { addNotification } from '../notificationAction'; // Assuming you have a notificationActions file
import axiosConfig from '../../../utils/axiosConfig';
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchRandomQuote = () => async (dispatch) => {
    dispatch(fetchQuoteRequest());
    dispatch(addNotification('quote', 'loading', 'Haetaan mietelausetta'));
    try {
        const response = await axiosConfig.get(`${apiUrl}/random-quote`);
        dispatch(fetchQuoteSuccess(response.data.quote));
        dispatch(addNotification('quote', 'success', 'Mietelause haettu'));
    } catch (error) {
        dispatch(fetchQuoteFailure(error.message));
        dispatch(addNotification('quote', 'fail', 'Mietelauseen hakeminen epäonnistui'));
    }
};