import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrders, fetchOrdersBySearchTerm, fetchAllOrders } from '../redux/actions/thunks/Orders'; // Import fetchAllOrders
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import LoadingIcon from './LoadingIcon'; // Import the loading icon component
import DatePicker, { registerLocale } from 'react-datepicker'; // Import DatePicker and registerLocale
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker CSS
import { fi } from 'date-fns/locale'; // Import Finnish locale from date-fns
import { startOfWeek, endOfWeek } from 'date-fns'; // Import format function from date-fns
import { clearOrders } from '../redux/actions/ordersAction';
import { FaCheck, FaTimes } from 'react-icons/fa';

// Register the Finnish locale with react-datepicker
registerLocale('fi', fi);

const OrdersPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTab, setSelectedTab] = useState('OPEN');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [disableButtons, setDisableButtons] = useState(false);
  const { loading, error, orders } = useSelector(state => state.orders);
  const [showMessage, setShowMessage] = useState(false);
  const [Message, setMessage] = useState('');
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notification);

  useEffect(() => {
    if (notifications.message === 'Avoimet tilaukset haettu Easoftista' ||
      notifications.requestStatus === 'error'
    ) {
      setMessage(notifications.message);
      setShowMessage(true);
      // Set isSubmitted to false after 5 seconds
      setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }
  }, [notifications]);

  useEffect(() => {
    // Check if startDate and endDate are selected before fetching orders
    if (!startDate || !endDate) {
      return;
    }
    
    dispatch(fetchOrders(selectedTab, startDate, endDate, 20));
  }, [dispatch, selectedTab, startDate, endDate]);

  const handleSearch = (value) => {
    if(value !== ''){
      setSelectedTab('NONE')
      setDisableButtons(true);
      setStartDate(null);
      setEndDate(null);
      setSearchTerm(value.toLowerCase());
      dispatch(fetchOrdersBySearchTerm(value.toLowerCase(), 20));
    } else {
      setDisableButtons(false);
      dispatch(clearOrders());
      setSearchTerm('');
    }
  };

  const handleLoadMore = () => {
    if (startDate && endDate) {
      dispatch(fetchOrders(selectedTab, startDate, endDate, orders.length + 20));
    } else if (searchTerm !== '') {
      dispatch(fetchOrdersBySearchTerm(searchTerm, orders.length + 20));
    }
    // Dispatch the fetchOrdersBySearchTerm thunk action creator with the searchTerm and limit
  };

  // Filter orders based on search term and selected tab
  const filteredOrders = orders.filter(order =>
    (order.customer_name.toLowerCase().includes(searchTerm) ||
      order.number.toLowerCase().includes(searchTerm))
  );

  const handleStartDateChange = (date) => {
    // Set the startDate to the beginning of the week containing the selected date
    const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 }); // Assuming Monday is the first day of the week
  
    // Update the startDate state
    setStartDate(startOfWeekDate);
  };

  const handleEndDateChange = (date) => {
    // Set the startDate to the beginning of the week containing the selected date
    const startOfWeekDate = endOfWeek(date, { weekStartsOn: 1 }); // Assuming Monday is the first day of the week
  
    // Update the startDate state
    setEndDate(startOfWeekDate);
  };

  const LoadMoreButton = ({ onClick }) => {
    return (
      <button onClick={onClick} className="gradoGreenButton" style={{marginLeft: '10px'}}>
        Lataa lisää...
      </button>
    );
  };

  return (
    <div className="orders-page">
      <div className="orders-tabs">
        <SearchBar placeholder="Etsi tilaus..." handleChange={handleSearch} />
        <button
          className={selectedTab === 'OPEN' ? 'selected-tab' : 'tab'}
          onClick={() => setSelectedTab('OPEN')}
          disabled={loading || disableButtons}
        >
          Avoimet tilaukset
        </button>
        <button
          className={selectedTab === 'DELIVERED' ? 'selected-tab' : 'tab'}
          onClick={() => setSelectedTab('DELIVERED')}
          disabled={loading || disableButtons}
        >
          Suljetut tilaukset
        </button>
        <button
          className="tab form-button-new"
          onClick={() => dispatch(fetchAllOrders())}
          disabled={loading || disableButtons}
          style={{ marginLeft: '50px' }}
        >
          Tuo tilaukset Easoftista
        </button>
        {loading && <LoadingIcon />}
        {showMessage && (
          <div className="notification">
            {notifications.requestStatus === 'success' && (
              <>
                <FaCheck className="pulse-spin notification-success" style={{ marginLeft: '25px', fontSize: '2em' }} />
                <span className="notification-text notification-success">{Message}</span>
              </>
            )}
            {notifications.requestStatus === 'error' && (
              <>
                <FaTimes className="pulse-spin notification-error" style={{ marginLeft: '25px', fontSize: '2em' }} />
                <span className="notification-text notification-error">{Message}</span>
              </>
            )}
          </div>
        )}
      </div>
  
      <div className="date-filters">
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          locale="fi" // Set the locale to Finnish
          dateFormat="dd.MM.yyyy"
          showWeekNumbers
          placeholderText="Asennusviikko alkaen"
          todayButton="Tänään"
          todayButtonClassName="today-button"
          onClickOutside={date => {
            if (date === null) {
              const today = new Date();
              setStartDate(today);
            }
          }}
        />
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          locale="fi" // Set the locale to Finnish
          dateFormat="dd.MM.yyyy"
          showWeekNumbers
          placeholderText="Asennusviikko päättyen"
          todayButton="Tänään"
          todayButtonClassName="today-button"
          onClickOutside={date => {
            if (date === null) {
              const today = new Date();
              setEndDate(today);
            }
          }}
        />
      </div>
  
      {(!startDate || !endDate) && searchTerm === '' && (
        <p className="no-orders-message">Valitse ajanjakso tai etsi tilausta hakukentästä</p>
      )}
  
      <div className="orders-list">
        {(startDate && endDate && orders.length === 0) || (searchTerm !== '' && orders.length === 0) ? (
          <p className="no-orders-message">Ei tilauksia, muuta hakuehtoja</p>
        ) : (
          filteredOrders.map(order => (
            <Link to={`/order/${order._id}`} key={order._id} className="order-card">
              <div className="order-card__number">{order.number}</div>
              <div className="order-card__customer">{order.customer_name}</div>
            </Link>
          ))
        )}
      </div>
      {!loading && !error && orders.length >= 20 && (
        <LoadMoreButton onClick={handleLoadMore} />
      )}
    </div>
  );
};

export default OrdersPage;
