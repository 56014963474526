import {
  fetchSpentResourcesRequest,
  fetchSpentResourcesSuccess,
  fetchSpentResourcesFailure,
  updateSpentResourcesRequest,
  updateSpentResourcesSuccess,
  updateSpentResourcesFailure,
  fetchWeeksRequest,
  fetchWeeksSuccess,
  fetchWeeksFailure,
  updateWeekRequest,
  updateWeekSuccess,
  updateWeekFailure,
} from '../spentResourcesAction';

import { addNotification } from '../notificationAction'; // Assuming you have notification actions
import axiosConfig from '../../../utils/axiosConfig';
const apiUrl = process.env.REACT_APP_API_URL;

// Thunk function to fetch spent resources
export const fetchSpentResources = () => async (dispatch) => {
  dispatch(addNotification('spentResources', 'loading', 'Haetaan työaikoja'));
  dispatch(fetchSpentResourcesRequest());
  try {
    const response = await axiosConfig.get(`${apiUrl}/resources/`);
    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    const spentResources = response.data;
    dispatch(fetchSpentResourcesSuccess(spentResources));
    dispatch(addNotification('spentResources', 'success', 'Työajat haettu'));
  } catch (error) {
    dispatch(fetchSpentResourcesFailure(error.message));
    dispatch(addNotification('spentResources', 'fail', 'Työaikojen haku epäonnistui'));
  }
};

// Thunk function to update spent resources
export const updateSpentResources = (updatedFields) => async (dispatch) => {
  dispatch(addNotification('spentResources', 'loading', 'Päivitetään työaikoja...'));
  dispatch(updateSpentResourcesRequest());
  try {
    const response = await axiosConfig.put(`${apiUrl}/resources/`, updatedFields);
    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    const updatedResources = response.data;
    dispatch(updateSpentResourcesSuccess(updatedResources));
    dispatch(addNotification('spentResources', 'success', 'Työajat päivitetty onnistuneesti'));
  } catch (error) {
    dispatch(updateSpentResourcesFailure(error.message));
    dispatch(addNotification('spentResources', 'fail', 'Työaikojen päivitys epäonnistui'));
  }
};

// Thunk function to fetch weeks
export const fetchWeeks = () => async (dispatch) => {
  dispatch(addNotification('weeks', 'loading', 'Haetaan työntekijämääriä'));
  dispatch(fetchWeeksRequest());
  try {
    const response = await axiosConfig.get(`${apiUrl}/weeks/`);
    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    const weeks = response.data;
    dispatch(fetchWeeksSuccess(weeks));
    dispatch(addNotification('weeks', 'success', 'Työntekijämäärä haettu onnistuneesti'));
  } catch (error) {
    dispatch(fetchWeeksFailure(error.message));
    dispatch(addNotification('weeks', 'fail', 'Työntekijämäärien haku epäonnistui'));
  }
};

// Thunk function to update week
export const updateWeek = (updatedFields) => async (dispatch) => {
  dispatch(addNotification('weeks', 'loading', 'Päivitetään työntekijämääriä'));
  dispatch(updateWeekRequest());
  try {
    const response = await axiosConfig.put(`${apiUrl}/weeks/`, updatedFields);
    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    const updatedWeek = response.data;
    dispatch(updateWeekSuccess(updatedWeek));
    dispatch(addNotification('weeks', 'success', 'Työntekijämäärä päivitetty onnistuneesti'));
  } catch (error) {
    dispatch(updateWeekFailure(error.message));
    dispatch(addNotification('weeks', 'fail', 'Työntekijämäärien päivitys epäonnistui'));
  }
};