import {
    FETCH_STORAGE_REQUEST,
    FETCH_STORAGE_SUCCESS,
    FETCH_STORAGE_FAILURE,
    ADD_STORAGE_ITEM_REQUEST,
    ADD_STORAGE_ITEM_SUCCESS,
    ADD_STORAGE_ITEM_FAILURE,
    UPDATE_STORAGE_ITEM_REQUEST,
    UPDATE_STORAGE_ITEM_SUCCESS,
    UPDATE_STORAGE_ITEM_FAILURE,
    DELETE_STORAGE_ITEM_REQUEST,
    DELETE_STORAGE_ITEM_SUCCESS,
    DELETE_STORAGE_ITEM_FAILURE,
    FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAILURE,
  FETCH_STORAGE_PREDICTION_REQUEST,
  FETCH_STORAGE_PREDICTION_SUCCESS,
  FETCH_STORAGE_PREDICTION_FAILURE,
  FETCH_RESOURCE_PREDICTION_REQUEST,
  FETCH_RESOURCE_PREDICTION_SUCCESS,
  FETCH_RESOURCE_PREDICTION_FAILURE,
  UPLOAD_ITEM_IMAGE_REQUEST,
  UPLOAD_ITEM_IMAGE_SUCCESS,
  UPLOAD_ITEM_IMAGE_FAILURE
  } from '../actionTypes';

export const fetchStorageRequest = () => ({
    type: FETCH_STORAGE_REQUEST,
  });
  
  export const fetchStorageSuccess = (storage) => ({
    type: FETCH_STORAGE_SUCCESS,
    payload: storage,
  });
  
  export const fetchStorageFailure = (error) => ({
    type: FETCH_STORAGE_FAILURE,
    payload: error,
  });
  
  export const addStorageItemRequest = () => ({
    type: ADD_STORAGE_ITEM_REQUEST,
  });
  
  export const addStorageItemSuccess = (item) => ({
    type: ADD_STORAGE_ITEM_SUCCESS,
    payload: item,
  });
  
  export const addStorageItemFailure = (error) => ({
    type: ADD_STORAGE_ITEM_FAILURE,
    payload: error,
  });
  
  export const updateStorageItemRequest = () => ({
    type: UPDATE_STORAGE_ITEM_REQUEST,
  });
  
  export const updateStorageItemSuccess = (item) => ({
    type: UPDATE_STORAGE_ITEM_SUCCESS,
    payload: item,
  });
  
  export const updateStorageItemFailure = (error) => ({
    type: UPDATE_STORAGE_ITEM_FAILURE,
    payload: error,
  });

  export const deleteStorageItemSuccess = (item) => ({
    type: DELETE_STORAGE_ITEM_SUCCESS,
    payload: item,
  });
  
  export const deleteStorageItemFailure = (error) => ({
    type: DELETE_STORAGE_ITEM_FAILURE,
    payload: error,
  });

  export const deleteStorageItemRequest = () => ({
    type: DELETE_STORAGE_ITEM_REQUEST,
  });

  export const fetchTransactionsRequest = () => ({
    type: FETCH_TRANSACTIONS_REQUEST,
  });
  
  export const fetchTransactionsSuccess = (transactions) => ({
    type: FETCH_TRANSACTIONS_SUCCESS,
    payload: transactions,
  });
  
  export const fetchTransactionsFailure = (error) => ({
    type: FETCH_TRANSACTIONS_FAILURE,
    payload: error,
  });
  
  export const addTransactionRequest = () => ({
    type: ADD_TRANSACTION_REQUEST,
  });
  
  export const addTransactionSuccess = (transaction) => ({
    type: ADD_TRANSACTION_SUCCESS,
    payload: transaction,
  });
  
  export const addTransactionFailure = (error) => ({
    type: ADD_TRANSACTION_FAILURE,
    payload: error,
  });

  export const fetchStoragePredictionRequest = () => ({
    type: FETCH_STORAGE_PREDICTION_REQUEST,
  });

  export const fetchStoragePredictionSuccess = (prediction) => ({
    type: FETCH_STORAGE_PREDICTION_SUCCESS,
    payload: prediction,
  });
  
  export const fetchStoragePredictionFailure = (error) => ({
    type: FETCH_STORAGE_PREDICTION_FAILURE,
    payload: error,
  });

  export const fetchResourcePredictionRequest = () => ({
    type: FETCH_RESOURCE_PREDICTION_REQUEST,
  });

  export const fetchResourcePredictionSuccess = (prediction) => ({
    type: FETCH_RESOURCE_PREDICTION_SUCCESS,
    payload: prediction,
  });
  
  export const fetchResourcePredictionFailure = (error) => ({
    type: FETCH_RESOURCE_PREDICTION_FAILURE,
    payload: error,
  });

  export const uploadItemImageRequest = () => ({
    type: UPLOAD_ITEM_IMAGE_REQUEST
  });
  
  export const uploadItemImageSuccess = (uploadedItem) => ({
    type: UPLOAD_ITEM_IMAGE_SUCCESS,
    payload: uploadedItem
  });
  
  export const uploadItemImageFailure = (error) => ({
    type: UPLOAD_ITEM_IMAGE_FAILURE,
    payload: error
  });