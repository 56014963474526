import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import StoragePage from './components/StoragePage';
import OrderPage from './components/OrderPage';
import Calendar from './components/Calendar';
import "./components/styles.css";
import OrdersPage from './components/OrdersPage';
import ModifyResources from './components/ModifyResources';
import LoginPage from './components/LoginPage';
import UsersPage from './components/UsersPage.jsx';
import { setAuthorizationHeader } from './utils/axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from './redux/actions/thunks/Auth.js';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.users.currentUser);
  const { pathname } = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthorizationHeader(token);
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      // Check if token is expired
      if (decodedToken.exp < currentTime) {
        // Token is expired, log the user out
        dispatch(logoutUser());
        navigate('/login');
      }
    } else {
      // Redirect to login page if no token found
      navigate('/login');
    }
  }, [dispatch, navigate, pathname]);

  useEffect(() => {
    if (currentUser && currentUser.role !== 'admin' && (pathname.toLowerCase().includes('users') || pathname.toLowerCase().includes('resources'))) {
      console.log("jorma1");
      navigate('/calendar');
    }
    if (currentUser && (currentUser.role !== 'admin' && currentUser.role !== 'design') && pathname.toLowerCase().includes('resources')) {
      console.log("jorma2");
      navigate('/calendar');
    }
    if (!currentUser && pathname === '/') {
      console.log("jorma14");
      navigate('/login');
    }
  }, [currentUser, navigate, pathname]);

  return (
    <Routes>
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/order/:orderId" element={<OrderPage />} />
      <Route path="/storage" element={<StoragePage />} />
      <Route path="/calendar" element={<Calendar />} />
      <Route path="/resources" element={<ModifyResources />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<LoginPage />} />
      <Route path="/users" element={<UsersPage />} />
    </Routes>
  );
}

export default App;