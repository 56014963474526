import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTransactions } from '../redux/actions/thunks/Storage';
import LoadingIcon from './LoadingIcon'; // Import the loading icon component

const TransactionWindow = ({ onClose }) => {
  const transactions = useSelector(state => state.storage.transactions);
  const loading = useSelector(state => state.storage.loading); 
  const dispatch = useDispatch(); // Initialize dispatch

  const [visibleTransactions, setVisibleTransactions] = useState(60);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false); // State to track whether to show the load more button

  const formatAmount = (amount, action) => {
    let amountClassName = '';
    let formattedAmount = '';

    if (action === '') {
      amountClassName = amount < 0 ? 'negative' : 'positive';
      formattedAmount = `${amount < 0 ? '-' : '+'}${Math.abs(amount)} kpl`;
    } else {
      amountClassName = 'default';
      formattedAmount = `${amount} kpl`;
    }

    return <span className={`Transaction-Amount ${amountClassName}`}>{formattedAmount}</span>;
  };

  const listRef = useRef(null);

  useEffect(() => {
    dispatch(fetchTransactions(30));
  }, [dispatch]);

  useEffect(() => {
    const listRefCurrent = listRef.current;
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;

        // Show the load more button when reaching the bottom
        if (scrollHeight - scrollTop === clientHeight && !showLoadMoreButton && transactions.length >= 30) {
          setShowLoadMoreButton(true);
        }
      }
    };

    if (listRefCurrent) {
      listRefCurrent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listRefCurrent) {
        listRefCurrent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [showLoadMoreButton, transactions.length]); // Add showLoadMoreButton to dependency array

  // Function to handle loading more transactions
  const handleLoadMoreTransactions = () => {
    setVisibleTransactions(prevVisibleTransactions => prevVisibleTransactions + 30);
    dispatch(fetchTransactions(visibleTransactions));
    setShowLoadMoreButton(false); // Hide the load more button after clicking
  };

  return (
    <div className="Transaction-Overlay">
      <div className="Transaction-Container">
        <div className="Transaction-Header">
          <h2 className="Transaction-Title">Tapahtumat</h2>
          <button className="Transaction-CloseButton" onClick={onClose}>Sulje</button>
        </div>
        <div className="Transaction-ListContainer" ref={listRef}>
        {loading ? (
            <LoadingIcon /> 
          ) : (
            <ul className="Transaction-List">
              {transactions.slice(-visibleTransactions).map(transaction => (
                <li className="Transaction-Item" key={transaction._id}>
                  <div className="Transaction-Info">
                    <span className="Transaction-Person">{transaction.person}:</span>
                    <span className="Transaction-ItemDetails">
                      {transaction.item.isOther ? (
                        <>
                          {transaction.item.item} {transaction.item.info}
                        </>
                      ) : transaction.item.isHandRail ? (
                        <>
                          {transaction.item.handRail_woodType} {transaction.item.model}, {transaction.item.length}mm
                        </>
                      ) : (
                        <>
                          {transaction.item.woodType} {transaction.item.length}x{transaction.item.depth}x{transaction.item.thickness}mm
                        </>
                      )}
                    </span>
                    <span className="Transaction-Amount">{formatAmount(transaction.amount, transaction.action)}</span>
                    <span className="Transaction-Time">({new Date(transaction.time).toLocaleString('fi-FI')})</span>
                    {transaction.action && (
                      <span className={`Transaction-Action ${transaction.action === 'Poistettu' ? 'Transaction-ActionRemove' : transaction.action === 'Uusi' ? 'Transaction-ActionAdd' : transaction.action === 'Muokattu' ? 'Transaction-ActionModified' : ''}`}>
                        {transaction.action}
                      </span>
                    )}
                  </div>
                  {transaction.action === 'Muokattu' && (
                    <div className="Transaction-OldItemInfo">
                      <span className="Transaction-OldItemDetails">
                        {'Vanha: '}
                        {transaction.oldItem.isOther ? (
                          <>
                            {transaction.oldItem.item} {transaction.oldItem.info}
                          </>
                        ) : transaction.oldItem.isHandRail ? (
                          <>
                            {transaction.oldItem.handRail_woodType} {transaction.oldItem.model}, {transaction.oldItem.length}mm
                          </>
                        ) : (
                          <>
                            {transaction.oldItem.woodType} {transaction.oldItem.length}x{transaction.oldItem.depth}x{transaction.oldItem.thickness}mm
                          </>
                        )}
                      </span>
                      <span className="Transaction-OldAmount">{formatAmount(transaction.oldItem.amount)}</span>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
          {showLoadMoreButton && !loading && (
            <button onClick={handleLoadMoreTransactions} className="form-button form-button-submit">
              Lataa lisää...
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionWindow;
