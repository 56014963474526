import {
    FETCH_QUOTE_REQUEST,
    FETCH_QUOTE_SUCCESS,
    FETCH_QUOTE_FAILURE
} from '../actionTypes';

export const fetchQuoteRequest = () => ({
    type: FETCH_QUOTE_REQUEST
});

export const fetchQuoteSuccess = (quote) => ({
    type: FETCH_QUOTE_SUCCESS,
    payload: quote
});

export const fetchQuoteFailure = (error) => ({
    type: FETCH_QUOTE_FAILURE,
    payload: error
});