import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchOrder, updateOrder } from '../redux/actions/thunks/Orders';
import { FaCheck } from 'react-icons/fa';
import { fetchStorage } from '../redux/actions/thunks/Storage';
import { fetchUsers } from '../redux/actions/thunks/Auth.js';
import { getWeek, getYear } from 'date-fns';

const OrderPage = () => {
    const { orderId } = useParams();
    const dispatch = useDispatch();
    const order = useSelector(state => state.orders.currentOrder);
    const navigate = useNavigate();
    const notifications = useSelector(state => state.notification);
    const storage = useSelector(state => state.storage.storage);
    const [selectedTab, setSelectedTab] = useState('Suunnittelu'); // State to track selected tab
    const currentUser = useSelector(state => state.users.currentUser);

    const [formData, setFormData] = useState({
        woodType: '',
        woodType2: '',
        platformWoodType: '',
        squarePlatformWoodType: '',
        triangleWoodType: '',
        stepAmount: '',
        stepLength: '',
        stepAmount2: '',
        stepLength2: '',
        stepCutting: '',
        stepGluing: '',
        stepSanding: '',
        stepColoring1: '',
        stepColoring2: '',
        stepColoring3: '',
        stepPackaging: '',
        handRail: '',
        oneHandRail: '',
        twoHandRails: '',
        handRailAmount: '',
        handRail2Amount: '',
        handRail_woodType: '',
        handRailModel: '',
        handRail2Model: '',
        handRail_modifiedTimestamp: '',
        stepCutting_modifiedTimestamp: '',
        stepGluing_modifiedTimestamp: '',
        stepSanding_modifiedTimestamp: '',
        stepColoring1_modifiedTimestamp: '',
        stepColoring2_modifiedTimestamp: '',
        stepColoring3_modifiedTimestamp: '',
        stepPackaging_modifiedTimestamp: '',
        stepColor: '',
        ledColor: '',
        stepGroove: '',
        ledStrips: '',
        lStrips_modifiedTimestamp: '',
        ledStrips_modifiedTimestamp: '',
        lStrips: '',
        platforms: '',
        squarePlatforms: '',
        stairsModel: '',
        platformLength: '',
        squarePlatformLength: '',
        U_stairs: '',
        L_stairs: '',
        platformStairs: '',
        twoLengths: '',
        isRemoved: '',
        notes: '',
        lowerCoverWoodType: '',
    });

    useEffect(() => {
        // Fetch the single order when the component mounts
        dispatch(fetchUsers());
        dispatch(fetchStorage());
        dispatch(fetchOrder(orderId));
    }, [dispatch, orderId]); // Include orderId in the dependency array

    useEffect(() => {
        if (currentUser.role === "design") {
            setSelectedTab('Suunnittelu')
        }
        if (currentUser.role === "production") {
            setSelectedTab('Tuotanto')
        }
    }, [currentUser]); // Include orderId in the dependency array

    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (order) {
            setFormData({
                woodType: order.woodType || '',
                woodType2: order.woodType2 || '',
                platformWoodType: order.platformWoodType || '',
                squarePlatformWoodType: order.squarePlatformWoodType || '',
                triangleWoodType: order.triangleWoodType || '',
                stepAmount: order.stepAmount || '',
                stepLength: order.stepLength || '',
                stepAmount2: order.stepAmount2 || '',
                stepLength2: order.stepLength2 || '',
                stepCutting: order.stepCutting || '',
                stepGluing: order.stepGluing || '',
                stepSanding: order.stepSanding || '',
                stepColoring1: order.stepColoring1 || '',
                stepColoring2: order.stepColoring2 || '',
                stepColoring3: order.stepColoring3 || '',
                stepPackaging: order.stepPackaging || '',
                stepCutting_modifiedTimestamp: order.stepCutting_modifiedTimestamp || '',
                stepGluing_modifiedTimestamp: order.stepGluing_modifiedTimestamp || '',
                stepSanding_modifiedTimestamp: order.stepSanding_modifiedTimestamp || '',
                stepColoring1_modifiedTimestamp: order.stepColoring1_modifiedTimestamp || '',
                stepColoring2_modifiedTimestamp: order.stepColoring2_modifiedTimestamp || '',
                stepColoring3_modifiedTimestamp: order.stepColoring3_modifiedTimestamp || '',
                stepPackaging_modifiedTimestamp: order.stepPackaging_modifiedTimestamp || '',
                handRail: order.handRail || '',
                handRail_modifiedTimestamp: order.handRail_modifiedTimestamp || '',
                handRailAmount: order.handRailAmount || '',
                handRail2Amount: order.handRail2Amount || '',
                handRail_woodType: order.handRail_woodType || '',
                handRailModel: order.handRailModel || '',
                handRail2Model: order.handRail2Model || '',
                oneHandRail: order.oneHandRail || false,
                twoHandRails: order.twoHandRails || false,
                stepColor: order.stepColor || '',
                ledColor: order.ledColor || '',
                stepGroove: order.stepGroove || '',
                ledStrips: order.ledStrips || '',
                lStrips_modifiedTimestamp: order.lStrips_modifiedTimestamp || '',
                ledStrips_modifiedTimestamp: order.ledStrips_modifiedTimestamp || '',
                lStrips: order.lStrips || '',
                platforms: order.platforms || '',
                squarePlatforms: order.squarePlatforms || '',
                stairsModel: order.stairsModel || '',
                platformLength: order.platformLength || '',
                squarePlatformLength: order.squarePlatformLength || '',
                U_stairs: order.U_stairs || false,
                L_stairs: order.L_stairs || false,
                platformStairs: order.platformStairs || false,
                twoLengths: order.twoLengths || false,
                isRemoved: order.isRemoved || false,
                notes: order.notes || '',
                lowerCoverWoodType: order.lowerCoverWoodType || '',
            });
        }
    }, [order]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        // For checkboxes that should be mutually exclusive
        if (name === 'U_stairs' || name === 'L_stairs') {
            setFormData({
                ...formData, 
                U_stairs: name === 'U_stairs' ? checked : false, 
                L_stairs: name === 'L_stairs' ? checked : false 
            });
        } 
        // For oneHandRail checkbox
        else if (name === 'oneHandRail') {
            if (!checked) {
                console.log("clearaa käsijohteet");
                setFormData({
                    ...formData,
                    oneHandRail: false,
                    twoHandRails: false,   // Uncheck twoHandRails
                    handRailModel: '',     // Clear handRailModel
                    handRailAmount: '',    // Clear HandRailAmount
                    handRail2Model: '',    // Clear handRail2Model
                    handRail2Amount: '',    // Clear handRail2Amount
                    handRail_woodType: ''
                });
            } else {
                setFormData({
                    ...formData,
                    oneHandRail: true
                });
            }
        } 
        // For twoHandRails checkbox
        else if (name === 'twoHandRails') {
            if (!checked) {
                setFormData({
                    ...formData,
                    twoHandRails: false,
                    handRail2Model: '',   // Clear handRail2Model
                    handRail2Amount: ''   // Clear handRail2Amount
                });
            } else {
                setFormData({
                    ...formData,
                    twoHandRails: true
                });
            }
        } 
        // For other checkboxes and input fields
        else {
            const inputValue = type === 'checkbox' ? checked : value;
            setFormData({ ...formData, [name]: inputValue });
        }
    
        e.target.classList.add('modified');
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ''; // Return empty string if timestamp is not available
        const date = new Date(timestamp);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        };
        return date.toLocaleString('fi-FI', options);
    };

    const formatWeekNumber = (dateString) => {
        const date = new Date(dateString);
        const weekNumber = getWeek(date);
        const year = getYear(date);
        return `Viikko ${weekNumber}/${year}`;
    };

    const formattedDate = formatWeekNumber(order.delivery);

    // Extract unique wood types from storage
    const woodTypes = [...new Set(storage.map(item => item.woodType).filter(type => type !== null && type !== 'Käsijohteet'))];
    const handRail_woodTypes = [...new Set(storage.map(item => item.handRail_woodType).filter(type => type !== null))];
    const handRailModels = [...new Set(storage.map(item => item.handRailModel).filter(type => type !== null))];
    // Define stairs models
    const stairsModels = ['Lumo', 'Kide', 'Valo', 'Leiju', 'Kannel', 'Umpiporras'];

    const handleCancel = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (notifications.message === 'Tilaus päivitetty onnistuneesti') {
            setIsSubmitted(true);
            dispatch(fetchOrder(orderId));

            // Set isSubmitted to false after 5 seconds
            setTimeout(() => {
                setIsSubmitted(false);
            }, 5000);
        }
    }, [notifications, dispatch, orderId]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formData.stepLength > 0) {
            const matchingStorage = storage.filter(item => item.woodType === formData.woodType && item.length >= formData.stepLength);
            if (matchingStorage.length === 0) {
                alert(`Suoriin askelmiin vaadittavaa puuta ei löydy varastosta: ${formData.woodType} ${formData.stepLength}. Lisää tuote varastoon.`);
                return;
            }
            if (formData.lowerCoverWoodType && formData.stairsModel === ('Kide' || 'Valo' || 'Leiju' || 'Kannel')) {
                const matchingLowerCoverStorage = storage.filter(item => item.woodType === formData.lowerCoverWoodType && item.length >= formData.stepLength);
                if (matchingLowerCoverStorage.length === 0) {
                    alert(`Askelmien alakansiin vaadittavaa puuta ei löydy varastosta: ${formData.lowerCoverWoodType} ${formData.stepLength}. Lisää tuote varastoon.`);
                    return;
                }
            }
        }

        if (formData.stepLength2 > 0) {
            const matchingStorage = storage.filter(item => item.woodType === formData.woodType2 && item.length >= formData.stepLength2);
            if (matchingStorage.length === 0) {
                alert(`Suoriin askelmiin vaadittavaa puuta ei löydy varastosta: ${formData.woodType2} ${formData.stepLength2}. Lisää tuote varastoon.`);
                return;
            }
            if (formData.lowerCoverWoodType && formData.stairsModel === ('Kide' || 'Valo' || 'Leiju' || 'Kannel')) {
                const matchingLowerCoverStorage = storage.filter(item => item.woodType === formData.lowerCoverWoodType && item.length >= formData.stepLength2 && item.thickness > 15 && item.thickness < 25 );
                if (matchingLowerCoverStorage.length === 0) {
                    alert(`Askelmien alakansiin vaadittavaa puuta ei löydy varastosta: ${formData.lowerCoverWoodType} ${formData.stepLength2}. Lisää tuote varastoon.`);
                    return;
                }
            }
        }

        if (formData.platforms > 0) {
            const matchingStorage = storage.filter(item => item.woodType === formData.platformWoodType && item.length >= formData.platformLength);

            if (matchingStorage.length === 0) {
                alert(`Tasoon vaadittavaa puuta ei löydy varastosta: ${formData.platformWoodType} ${formData.platformLength}. Lisää tuote varastoon.`);
                return;
            }
            if (formData.lowerCoverWoodType && formData.stairsModel === ('Kide' || 'Valo' || 'Leiju' || 'Kannel')) {
                const matchingLowerCoverStorage = storage.filter(item => item.woodType === formData.lowerCoverWoodType && item.length >= formData.platformLength && item.thickness > 15 && item.thickness < 25 );
                if (matchingLowerCoverStorage.length === 0) {
                    alert(`Tason alakanteen vaadittavaa puuta ei löydy varastosta: ${formData.lowerCoverWoodType} ${formData.platformLength}. Lisää tuote varastoon.`);
                    return;
                }
            }
        }

        if (formData.squarePlatforms > 0) {
            const matchingStorage = storage.filter(item => item.woodType === formData.squarePlatformWoodType && item.length >= formData.squarePlatformLength);
            if (matchingStorage.length === 0) {
                alert(`Neliötasoon vaadittavaa puuta ei löydy varastosta: ${formData.squarePlatformWoodType} ${formData.squarePlatformLength}. Lisää tuote varastoon.`);
                return;
            }
            if (formData.lowerCoverWoodType && formData.stairsModel === ('Kide' || 'Valo' || 'Leiju' || 'Kannel')) {
                const matchingLowerCoverStorage = storage.filter(item => item.woodType === formData.lowerCoverWoodType && item.length >= formData.squarePlatformLength && item.thickness > 15 && item.thickness < 25 );
                if (matchingLowerCoverStorage.length === 0) {
                    alert(`Neliötason alakanteen vaadittavaa puuta ei löydy varastosta: ${formData.lowerCoverWoodType} ${formData.squarePlatformLength}. Lisää tuote varastoon.`);
                    return;
                }
            }
        }

        if (formData.L_stairs || formData.U_stairs) {
            const matchingStorage = storage.filter(item => item.woodType === formData.triangleWoodType && item.length >= Math.min(formData.stepLength + 400, 1500));
            if (matchingStorage.length === 0) {
                alert(`Kiertäviin vaadittavaa puuta ei löydy varastosta: ${formData.triangleWoodType} ${Math.ceil(Math.min(formData.stepLength + 400, 1500) / 100) * 100}. Lisää tuote varastoon.`);
                return;
            }
            if (formData.lowerCoverWoodType && formData.stairsModel === ('Kide' || 'Valo' || 'Leiju' || 'Kannel')) {
                const matchingLowerCoverStorage = storage.filter(item => item.woodType === formData.lowerCoverWoodType && item.length >= Math.min(formData.stepLength + 400, 1500) && item.thickness > 15 && item.thickness < 25 );
                if (matchingLowerCoverStorage.length === 0) {
                    alert(`Kiertävien askelmien alakansiin vaadittavaa puuta ei löydy varastosta: ${formData.lowerCoverWoodType} ${Math.ceil(Math.min(formData.stepLength + 400, 1500) / 100) * 100}. Lisää tuote varastoon.`);
                    return;
                }
            }
        }

        if (formData.oneHandRail) {
            const matchingStorage = storage.filter(item => item.handRail_woodType === formData.handRail_woodType && item.handRailModel === formData.handRailModel);
            if (matchingStorage.length === 0) {
                alert(`Käsijohteeseen vaadittavaa puuta ei löydy varastosta: ${formData.handRail_woodType} ${formData.handRailModel}. Lisää tuote varastoon.`);
                return;
            }
        }

        if (formData.twoHandRails) {
            const matchingStorage = storage.filter(item => item.handRail_woodType === formData.handRail_woodType && item.handRailModel === formData.handRail2Model);
            if (matchingStorage.length === 0) {
                alert(`Käsijohteeseen vaadittavaa puuta ei löydy varastosta: ${formData.handRail_woodType} ${formData.handRail2Model}. Lisää tuote varastoon.`);
                return;
            }
        }

        const modifiedFields = {}; // Object to store modified fields

        // Find all modified form inputs
        const formInputs = document.querySelectorAll('.form-input.modified');

        // Update the modified timestamp for each modified field
        formInputs.forEach(input => {
            const fieldName = input.getAttribute('name');
            if (fieldName) {
                modifiedFields[`${fieldName}_modifiedTimestamp`] = new Date();
            }
        });

        // Update the order with the new data
        dispatch(updateOrder(orderId, { ...formData, ...modifiedFields }));

    };

    const isTriangleDisabled = formData.stairsModel === 'Leiju';

    useEffect(() => {
        if (isTriangleDisabled) {
            setFormData(prevFormData => ({
                ...prevFormData,
                U_stairs: false,
                L_stairs: false,
                triangleWoodType: ''
            }));
        }
    }, [isTriangleDisabled]);



    return (
        <div>
            <div className="customer-info" style={{marginTop: '20px'}}>
                <div>
                    {order.number} {order.customer_name} <span style={{ marginLeft: '10px', fontWeight: 'normal' }}>{formattedDate}</span>
                </div>
            </div>

            <div className="orders-tabs" style={{ justifyContent: 'center' }}>
                <button className={selectedTab === 'Tuotanto' ? 'selected-tab' : 'tab'} onClick={() => setSelectedTab('Tuotanto')}>Tuotanto</button>
                <button className={selectedTab === 'Suunnittelu' ? 'selected-tab' : 'tab'} onClick={() => setSelectedTab('Suunnittelu')}>Suunnittelu</button>
            </div>



            <form onSubmit={handleSubmit} className="order-form">
                <div className='order-container'>
                    {selectedTab === 'Suunnittelu' && (
                        <div>
                            <div className="checkbox-field">
                                <div className="form-group">
                                    <label htmlFor="woodType">Porrasmalli:</label>
                                    <select
                                        className="form-input"
                                        id="stairsModel"
                                        name="stairsModel"
                                        value={formData.stairsModel}
                                        onChange={handleChange}
                                        style={{ width: "180px" }}
                                    >
                                        <option value=""></option>
                                        {stairsModels.map((stairsModel, index) => (
                                            <option key={index} value={stairsModel}>{stairsModel}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group-container">
                                    <div className="form-group-checkbox">

                                        <input type="checkbox" id="U_stairs" name="U_stairs" checked={formData.U_stairs} onChange={handleChange} disabled={isTriangleDisabled} />
                                        <label htmlFor="U_stairs">Kiertävä U-porras</label>
                                    </div>
                                    <div className="form-group-checkbox">

                                        <input type="checkbox" id="L_stairs" name="L_stairs" checked={formData.L_stairs} onChange={handleChange} disabled={isTriangleDisabled} />
                                        <label htmlFor="L_stairs">Kiertävä L-porras</label>
                                    </div>
                                </div>
                                <div className="form-group-container">
                                    <div className="form-group-checkbox">

                                        <input type="checkbox" id="twoLengths" name="twoLengths" checked={formData.twoLengths} onChange={handleChange} />
                                        <label htmlFor="twoLengths">Useampaa suoraa</label>
                                    </div>
                                    <div className="form-group-checkbox">

                                        <input type="checkbox" id="platformStairs" name="platformStairs" checked={formData.platformStairs} onChange={handleChange} />
                                        <label htmlFor="platformStairs">Tasollinen porras</label>
                                    </div>
                                </div>
                                <div className="form-group-container">
                                    <div className="form-group-checkbox" style={{ marginRight: '12px' }}>

                                        <input type="checkbox" id="oneHandRail" name="oneHandRail" checked={formData.oneHandRail} onChange={handleChange} />
                                        <label htmlFor="oneHandRail">Käsijohdetta</label>
                                    </div>
                                    {formData.oneHandRail && (
                                        <div className="form-group-checkbox">

                                            <input type="checkbox" id="twoHandRails" name="twoHandRails" checked={formData.twoHandRails} onChange={handleChange} />
                                            <label htmlFor="twoHandRails">2 käsijohdetta</label>
                                        </div>
                                    )}
                                </div>
                                {formData.oneHandRail && (
                                <div className="form-group">
                                    <label htmlFor="handRail_woodType">Käsijohteiden puulaji:</label>
                                    <select
                                        className="form-input"
                                        id="handRail_woodType"
                                        name="handRail_woodType"
                                        value={formData.handRail_woodType}
                                        onChange={handleChange}
                                        style={{ width: "180px" }}
                                    >
                                        <option value=""></option>
                                        {handRail_woodTypes.map((handRail_woodType, index) => (
                                            <option key={index} value={handRail_woodType}>{handRail_woodType}</option>
                                        ))}
                                    </select>
                                </div>
                                )}
                            </div>

                            <div className="form-columns">
                                <div className='form-column'>
                                    <div className="form-group">
                                        <label htmlFor="stepGroove">Liukuesteuritus:</label>
                                        <input type="text" className="form-input" id="stepGroove" name="stepGroove" value={formData.stepGroove} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="stepColor">Askelman sävy:</label>
                                        <input type="text" className="form-input" id="stepColor" name="stepColor" value={formData.stepColor} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="ledColor">LED sävy:</label>
                                        <input type="text" className="form-input" id="ledColor" name="ledColor" value={formData.ledColor} onChange={handleChange} />
                                    </div>
                                    {(formData.L_stairs || formData.U_stairs) && (
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="triangleWoodType">Kiertävien puulaji:</label>
                                                <select
                                                    className="form-input"
                                                    id="triangleWoodType"
                                                    name="triangleWoodType"
                                                    value={formData.triangleWoodType}
                                                    onChange={handleChange}
                                                    style={{ width: "190px" }}
                                                >
                                                    <option value=""></option>
                                                    {woodTypes.map((triangleWoodType, index) => (
                                                        <option key={index} value={triangleWoodType}>{triangleWoodType}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    {(formData.stairsModel === "Kide" || formData.stairsModel === "Leiju" || formData.stairsModel === "Valo" || formData.stairsModel === "Kannel") && (
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="lowerCoverWoodType">Alakansien puulaji:</label>
                                                <select
                                                    className="form-input"
                                                    id="lowerCoverWoodType"
                                                    name="lowerCoverWoodType"
                                                    value={formData.lowerCoverWoodType}
                                                    onChange={handleChange}
                                                    style={{ width: "190px" }}
                                                >
                                                    <option value=""></option>
                                                    {woodTypes.map((lowerCoverWoodType, index) => (
                                                        <option key={index} value={lowerCoverWoodType}>{lowerCoverWoodType}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-column">
                                    <div>
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="woodType">Suorien puulaji:</label>
                                                <select
                                                    className="form-input"
                                                    id="woodType"
                                                    name="woodType"
                                                    value={formData.woodType}
                                                    onChange={handleChange}
                                                    style={{ width: "180px" }}
                                                >
                                                    <option value=""></option>
                                                    {woodTypes.map((woodType, index) => (
                                                        <option key={index} value={woodType}>{woodType}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="stepLength">Suorien pituus:</label>
                                            <input type="text" className="form-input" id="stepLength" name="stepLength" value={formData.stepLength} onChange={handleChange} pattern="[0-9]*" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="stepAmount">Suoria kpl:</label>
                                            <input type="text" className="form-input" id="stepAmount" name="stepAmount" value={formData.stepAmount} onChange={handleChange} pattern="[0-9]*" />
                                        </div>
                                    </div>
                                    {/* Add more form-row divs for other fields */}
                                </div>
                                {(formData.twoLengths || formData.L_stairs || formData.U_stairs || formData.oneHandRail) && (
                                    <div className="form-column">
                                        {formData.twoLengths && (
                                            <div>
                                                <div>
                                                    <div className="form-group">
                                                        <label htmlFor="woodType">2. suorien puulaji:</label>
                                                        <select
                                                            className="form-input"
                                                            id="woodType2"
                                                            name="woodType2"
                                                            value={formData.woodType2}
                                                            onChange={handleChange}
                                                            style={{ width: "180px" }}
                                                        >
                                                            <option value=""></option>
                                                            {woodTypes.map((woodType2, index) => (
                                                                <option key={index} value={woodType2}>{woodType2}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="stepLength2">2. suorien pituus:</label>
                                                    <input type="text" className="form-input" id="stepLength2" name="stepLength2" value={formData.stepLength2} onChange={handleChange} pattern="[0-9]*" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="stepAmount2">2. suoria kpl:</label>
                                                    <input type="text" className="form-input" id="stepAmount2" name="stepAmount2" value={formData.stepAmount2} onChange={handleChange} pattern="[0-9]*" />
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                )}

                                <div className="form-column">
                                    {formData.platformStairs && (
                                        <div>
                                            <div>
                                                <div className="form-group">
                                                    <label htmlFor="platformWoodType">Tasojen puulaji:</label>
                                                    <select
                                                        className="form-input"
                                                        id="platformWoodType"
                                                        name="platformWoodType"
                                                        value={formData.platformWoodType}
                                                        onChange={handleChange}
                                                        style={{ width: "180px" }}
                                                    >
                                                        <option value=""></option>
                                                        {woodTypes.map((platformWoodType, index) => (
                                                            <option key={index} value={platformWoodType}>{platformWoodType}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="platforms">Tasoja kpl:</label>
                                                <input type="text" className="form-input" id="platforms" name="platforms" value={formData.platforms} onChange={handleChange} pattern="[0.0-9.9]*" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="platformLength">Tasojen pituus:</label>
                                                <input type="text" className="form-input" id="platformLength" name="platformLength" value={formData.platformLength} onChange={handleChange} pattern="[0-9]*" />
                                            </div>
                                        </div>
                                    )}
                                    {formData.oneHandRail && (
                                        <div>
                                            <div>
                                                <div className="form-group">
                                                    <label htmlFor="handRailModel">Käsijohteiden malli:</label>
                                                    <select
                                                        className="form-input"
                                                        id="handRailModel"
                                                        name="handRailModel"
                                                        value={formData.handRailModel}
                                                        onChange={handleChange}
                                                        style={{ width: "180px" }}
                                                    >
                                                        <option value=""></option>
                                                        {handRailModels.map((handRailModel, index) => (
                                                            <option key={index} value={handRailModel}>{handRailModel}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="handRailAmount">Käsijohdesalkoja kpl:</label>
                                                <input type="text" className="form-input" id="handRailAmount" name="handRailAmount" value={formData.handRailAmount} onChange={handleChange} />
                                            </div>
                                        </div>
                                    )}
                                </div>


                                <div className="form-column">
                                    {formData.platformStairs && (
                                        <div>
                                            <div>
                                                <div className="form-group">
                                                    <label htmlFor="squarePlatformWoodType">Neliötasojen puulaji:</label>
                                                    <select
                                                        className="form-input"
                                                        id="squarePlatformWoodType"
                                                        name="squarePlatformWoodType"
                                                        value={formData.squarePlatformWoodType}
                                                        onChange={handleChange}
                                                        style={{ width: "180px" }}
                                                    >
                                                        <option value=""></option>
                                                        {woodTypes.map((squarePlatformWoodType, index) => (
                                                            <option key={index} value={squarePlatformWoodType}>{squarePlatformWoodType}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="squarePlatforms">Neliötasoja kpl:</label>
                                                <input type="text" className="form-input" id="squarePlatforms" name="squarePlatforms" value={formData.squarePlatforms} onChange={handleChange} pattern="[0-9]*" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="squarePlatformLength">Neliötasojen pituus:</label>
                                                <input type="text" className="form-input" id="squarePlatformLength" name="squarePlatformLength" value={formData.squarePlatformLength} onChange={handleChange} pattern="[0-9]*" />
                                            </div>
                                        </div>
                                    )}
                                    {(formData.twoHandRails && formData.oneHandRail) && (
                                        <div>
                                            <div>
                                                <div className="form-group">
                                                    <label htmlFor="handRail2Model">2. Käsijohteiden malli:</label>
                                                    <select
                                                        className="form-input"
                                                        id="handRail2Model"
                                                        name="handRail2Model"
                                                        value={formData.handRail2Model}
                                                        onChange={handleChange}
                                                        style={{ width: "180px" }}
                                                    >
                                                        <option value=""></option>
                                                        {handRailModels.map((handRail2Model, index) => (
                                                            <option key={index} value={handRail2Model}>{handRail2Model}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="handRail2Amount">2. Käsijohdesalkoja kpl:</label>
                                                <input type="text" className="form-input" id="handRail2Amount" name="handRail2Amount" value={formData.handRail2Amount} onChange={handleChange} />
                                            </div>
                                        </div>
                                    )}
                                </div>


                            </div>
                        </div>
                    )}
                    {selectedTab === 'Tuotanto' && (
                        <div className='form-columns'>
                            <div className="form-column">
                                <div className="form-row">
                                    <div className="form-group">
                                        <label htmlFor="stepCutting">Leikkaus:</label>
                                        <input type="text" className="form-input" id="stepCutting" name="stepCutting" value={formData.stepCutting} onChange={handleChange} />
                                        <p className="modified-timestamp">{formatTimestamp(formData.stepCutting_modifiedTimestamp)}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="stepGluing">Liimaus:</label>
                                        <input type="text" className="form-input" id="stepGluing" name="stepGluing" value={formData.stepGluing} onChange={handleChange} />
                                        <p className="modified-timestamp">{formatTimestamp(formData.stepGluing_modifiedTimestamp)}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="stepSanding">Hionta:</label>
                                        <input type="text" className="form-input" id="stepSanding" name="stepSanding" value={formData.stepSanding} onChange={handleChange} />
                                        <p className="modified-timestamp">{formatTimestamp(formData.stepSanding_modifiedTimestamp)}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="handRail">Käsijohteet tehty:</label>
                                        <input type="text" className="form-input" id="handRail" name="handRail" value={formData.handRail} onChange={handleChange} />
                                        <p className="modified-timestamp">{formatTimestamp(formData.handRail_modifiedTimestamp)}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="ledStrips">LED-listat:</label>
                                        <input type="text" className="form-input" id="ledStrips" name="ledStrips" value={formData.ledStrips} onChange={handleChange} />
                                        <p className="modified-timestamp">{formatTimestamp(formData.ledStrips_modifiedTimestamp)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-column">
                                <div className="form-group">
                                    <label htmlFor="stepColoring">1. värjäys:</label>
                                    <input type="text" className="form-input" id="stepColoring" name="stepColoring1" value={formData.stepColoring1} onChange={handleChange} />
                                    <p className="modified-timestamp">{formatTimestamp(formData.stepColoring1_modifiedTimestamp)}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="stepColoring">2. värjäys:</label>
                                    <input type="text" className="form-input" id="stepColoring" name="stepColoring2" value={formData.stepColoring2} onChange={handleChange} />
                                    <p className="modified-timestamp">{formatTimestamp(formData.stepColoring2_modifiedTimestamp)}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="stepColoring">3. värjäys :</label>
                                    <input type="text" className="form-input" id="stepColoring" name="stepColoring3" value={formData.stepColoring3} onChange={handleChange} />
                                    <p className="modified-timestamp">{formatTimestamp(formData.stepColoring3_modifiedTimestamp)}</p>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="lStrips">L-listat:</label>
                                    <input type="text" className="form-input" id="lStrips" name="lStrips" value={formData.lStrips} onChange={handleChange} />
                                    <p className="modified-timestamp">{formatTimestamp(formData.lStrips_modifiedTimestamp)}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="stepPackaging">Pakkaus:</label>
                                    <input type="text" className="form-input" id="stepPackaging" name="stepPackaging" value={formData.stepPackaging} onChange={handleChange} />
                                    <p className="modified-timestamp">{formatTimestamp(formData.stepPackaging_modifiedTimestamp)}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='notes-label'>
                    <label htmlFor="notes" className='notes-label'>Huomioita:</label>
                    <textarea
                        className="form-input-field"
                        id="notes"
                        name="notes"
                        value={formData.notes}
                        onChange={handleChange}
                        rows="4"
                        style={{ width: "100%" }}
                    />
                </div>
                <div className='form-buttons-container'>
                    <button type="button" className="form-button-order form-button-cancel" onClick={handleCancel}>Takaisin</button>
                    <button className="form-button-order form-button-submit" type="submit"> {isSubmitted && <FaCheck className="pulse-spin" style={{ position: 'absolute', marginLeft: '80px', color: 'lightgreen', fontSize: '1.2em' }} />}Tallenna</button>
                    <div className="isRemoved form-group-checkbox">
                        <input type="checkbox" id="isRemoved" name="isRemoved" checked={formData.isRemoved} onChange={handleChange} />
                        <label htmlFor="isRemoved">Poista kalenterista</label>
                    </div>
                </div>


            </form>

        </div>
    );
};

export default OrderPage;